import React, { useEffect } from "react";
import { useState } from "react";
//import  Image  from "@mui/material/Image";
import { BootstrapButton, SubHeading } from "../styleSheets/Style";
import InputAdornment from "@mui/material/InputAdornment";
import { TextField } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { fetchScore } from "../services/scoreCalculator";

function ScoreCalculatorPage() {
 
const [link,setLink] = useState(null);
const [phone,setPhone] = useState(null);
const [email,setEmail] = useState(null);
const [name,setName] = useState(null);
const [result,setResult] = useState(null);

useEffect(()=>{
   // console.log("result",result);
},[result]);
const calculateScore=async()=>{
 let formData =   new FormData();
 formData.append('link',link);
 formData.append('phone',phone);
 formData.append('email',email);
 formData.append('name',name);
console.log(link,phone,email,name);
 const response = await fetchScore(formData);
 
 let url = response?.data?.data?.url;
 
 window.open(url, '_blank', 'noreferrer');


}

 return (
    <>
      <div className="container overflow-hidden d-flex-col justify-content-center align-content-center">
        <div className="logo container text-center my-4">
          <img src={require("../images/iQuanta.png")} className="img-fuid mx-auto" width={250} alt="" />
        </div>

        <div className="container mt-3 p-2 text-center">
          <SubHeading className="text-center ">Calculate Your XAT Score 2024</SubHeading>
          <div
            className="p-4 mx-auto overflow-auto text-center"
            style={{
              border: "2px solid black",
              background: "var(--background-color)",
              height: "60vh",
              marginTop:"40px"
            }}
          >
            <TextField
              sx={{
                width: "50%",
                height: "50px",
                marginX:"20px",
                marginBottom: "40px",
                borderRadius: "8px",
              }}
              size="medium"
              id="name"
              name="name"
              label="Name"
              type="text"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
              variant="outlined"
              autoComplete="off"
              required
            />
            <TextField
             sx={{
                width: "50%",
                height: "50px",
                marginX:"20px",
                marginBottom: "40px",
                borderRadius: "8px",
              }}
              size="medium"
              id="phone"
              name="Phone"
              label="Phone Number"
              type="number"
              value={phone}
              onChange={(e) => {
                setPhone(e.target.value);
              }}
              variant="outlined"
              autoComplete="off"
              required
            />

            <TextField
              sx={{
                width: "50%",
                height: "50px",
                marginX:"20px",
                marginBottom: "40px",
                borderRadius: "8px",
              }}
              size="medium"
              id="email"
              name="email"
              label="Email"
              type="text"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              variant="outlined"
              autoComplete="off"
              required
            />

            <TextField
              sx={{
                width: "50%",
                height: "50px",
                marginX:"20px",
                marginBottom: "40px",
                borderRadius: "8px",
              }}
              size="medium"
              id="link"
              name="Score Sheet Link"
              label="Sheet Link"
              type="text"
              value={link}
              onChange={(e) => {
                setLink(e.target.value);
              }}
              variant="outlined"
              autoComplete="off"
              required
            />
          </div>

          <div className="d-flex justify-content-center  align-self-center gap-5 ">
            <BootstrapButton
              variant="contained mx-auto"
              height="30"
              width="150"
              sx={{
                color: link ? "black" : "white",
                p: 3,
                my: 2,
                borderRadius: "30px",
                backgroundColor: link ? "#d2d4d6" : "",
              }}
              onClick={() => calculateScore()}
              disabled={email==null||phone==null||name==null||link==null}
            >
              Click Now
            </BootstrapButton>
          </div>
        </div>
      </div>
    </>
  );
}

export default ScoreCalculatorPage;
