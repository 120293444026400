import { Box } from "@mui/material";
import React from "react";
import ImgMediaCard from "./MarketPlaceCard";

const MarketPlaceCardResponsive = ({ data }) => {
  return (
    <Box display='flex' flexWrap='wrap'>
      {data.map((item) => (
        <ImgMediaCard cardData={item} />
      ))}
    </Box>
  );
};

export default MarketPlaceCardResponsive;
