import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { encode, decode } from "base-64";
import { useSelector } from "react-redux";

function Protected(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const { Comp } = props;
  const {
    attemptId  
  } = useSelector((state) => state.mockData);

  useEffect(() => {
    if (typeof attemptId === undefined || attemptId === null) {
           navigate("/");
        }
  })

  return (
    <div>
      <Comp />
    </div>
  );
}

export default Protected;
