import React from "react";
import { Card, CardMedia, Button, CardActionArea } from "@mui/material";
import { styled } from "@mui/system";
import { useNavigate } from "react-router-dom";

const CardContainer = styled(Card)({
  borderRadius: 45,
  position: "relative",
  overflow: "hidden",
  transition: "all 0.3s ease, opacity 0.3s ease",
  // "&:hover .hoverButton": {
  //   transform: "translateY(0)",
  //   opacity: 1,
  // },
  "&:hover .hoverBackdrop": {
    backgroundColor: "rgba(0, 0, 0, 0)", // Change to your desired overlay color and opacity
  },
});

export const HoverButton = styled(Button)({
  position: "absolute",
  bottom: 35,
  right: 30,
  textTransform: "none",
  borderRadius: 5,
  transform: "translateY(100%)",
  opacity: 1,
  transform: "translateY(0)",
  transition: "all 0.3s ease, opacity 0.3s ease",
  zIndex: 10,
  fontFamily: "var(--font-inter)",
});

const HoverBackdrop = styled("div")({
  position: "absolute",
  backgroundColor: "rgba(0, 0, 0, 0.5)", 
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  transition: "all 0.3s ease, opacity 0.3s ease",
  zIndex: 1,
});

const HoveCard = ({ image, heading, path }) => {
  const navigate=useNavigate();
  return(
  <CardContainer>
    <CardActionArea onClick={() => navigate(path)}>
      <HoverBackdrop className="hoverBackdrop" />
      <CardMedia component="img" height="auto" image={image} alt="Modules" />
      <HoverButton
        onClick={() =>navigate(path)}
        sx={{ borderRadius: 10 }}
        className="hoverButton"
        variant="contained"
      >
        {heading}
      </HoverButton>
    </CardActionArea>
  </CardContainer>
)};

export default HoveCard;
