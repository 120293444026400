import Main from "./Pages/Main";
import { Route, Routes, useNavigate, useLocation } from "react-router-dom";
import { ThemeProvider, useTheme } from "@mui/material/styles";
import { theme } from "./styleSheets/Style";
import Instructions from "./Pages/Instructions";
import Terms from "./Pages/Terms";
import Protected from "./Components/Protected";
import AnalysisMain from "./Analysis/AnalysisMain";
import TopicAnalysis from "./Analysis/TopicAnalysis";
import SectionAnalysis from "./Analysis/SectionAnalysis";
import SubtopicAnalysis from "./Analysis/SubtopicAnalysis";
import DifficultyAnalysis from "./Analysis/DifficultyAnalysis";
import OverallAnalysis from "./Analysis/OverallAnalysis";
import ViewSolution from "./Pages/ViewSolution";
import UserAuth from "./Pages/UserAuth";
import LeaderBoard from "./Pages/LeaderBoard";
import AnalysisAcross from "./Pages/AnalysisAcross";
import ErrorTracker from "./Pages/ErrorTracker";
import GoalTracker from "./Pages/GoalTracker";
import MarketPlace from "./Pages/MarketPlace";
import MockComparison from "./Pages/MockComparison";
import OnBoarding from "./Pages/OnBoarding";
import MainUserAuth from "./Pages/MainUserAuth";
import MobileTemp from "./Pages/MobileTemp";
import React, { useEffect, useLayoutEffect, useState } from "react";
import ScoreVsPercentile from "./Pages/ScoreVsPercentile";
import version from "../package.json";
import ErrorPage from "./Pages/ErrorPage";
import ScoreCalculatorPage from "./Pages/ScoreCalculatorPage";
import { setWindowQuery } from "./store/slices/globalSlice";
import { useDispatch, useSelector } from "react-redux";
import GoalTrackerMob from "./Pages/mobPages/GoalTrackerMob";
import AnalysisAcrossMob from "./Pages/mobPages/AnalysisAcrossMob";
import ScoreVsPercentileMob from "./Pages/mobPages/ScoreVsPrecentileMob";
import ErrorTrackerMob from "./Pages/mobPages/ErrorTrackerMob";
import ViewSolutionMob from "./Pages/mobPages/viewSolutionMob";

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const { isMobile } = useSelector((state) => state.globalData);
  const dispatch = useDispatch();
  const [previousLocation, setPreviousLocation] = useState(null);
  const [isUserAuth, setIsUserAuth] = useState(false);
  const [authToken, setAuthToken] = useState(null);

  const isProduction = process.env.REACT_APP_BASE_URL === "";
  console.log(authToken);
  console.log(isUserAuth);

  useEffect(() => {
    const auth_token = localStorage.getItem("auth_token");
    setAuthToken(auth_token);
  }, [localStorage.getItem("auth_token")]);

  useLayoutEffect(() => {
    if (authToken) {
      setIsUserAuth(true);
    } else {
      setIsUserAuth(true);
    }
  }, [authToken]);

  // useEffect(() => {
  //   // function handleResize() {
  //   //   const isMobileOrTablet = window.matchMedia("(max-width:1000px)").matches;
  //   //   //console.log("Is mobile or tablet:", isMobileOrTablet, previousLocation);
  //   //   if (isMobileOrTablet) {
  //   //     if (!previousLocation) {
  //   //       //console.log("tyagi");
  //   //       setPreviousLocation(location.pathname);
  //   //       navigate("/mobileErrorPage");
  //   //     }
  //   //     // if (previousLocation) {
  //   //     //   navigate("/mobileErrorPage");
  //   //     // }
  //   //   }
  //   //   if (!isMobileOrTablet && previousLocation) {
  //   //     //console.log("mantha");
  //   //     navigate(previousLocation);
  //   //     setPreviousLocation(null);
  //   //   }
  //   // }
  //   // // Check the initial screen size
  //   // handleResize();
  //   // // Add event listener to handle screen resize
  //   // window.addEventListener("resize", handleResize);
  //   // // Cleanup by removing the event listener
  //   // return () => {
  //   //   window.removeEventListener("resize", handleResize);
  //   // };
  // }, [navigate, previousLocation, location]);

  // Rest of the component code...

  useEffect(() => {
    function handleResize() {
      const isMobileOrTablet = window.matchMedia("(max-width:980px)").matches;
      //console.log("Is mobile or tablet:", isMobileOrTablet, previousLocation);

      if (isMobileOrTablet) {
        if (!previousLocation) {
          setPreviousLocation(location.pathname);
          dispatch(setWindowQuery(true));
        }
        // if (previousLocation) {
        //   navigate("/mobileErrorPage");
        // }
      }
      if (!isMobileOrTablet && previousLocation) {
        navigate(previousLocation);
        setPreviousLocation(null);
        dispatch(setWindowQuery(false));
      }

      console.log("Mobile view ", isMobile);
    }

    // Check the initial screen size
    handleResize();

    // Add event listener to handle screen resize
    window.addEventListener("resize", handleResize);

    // Cleanup by removing the event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [navigate, previousLocation, location]);

  console.log("🚀 ~ App ~ isMobile:", isMobile);

  return (
    <ThemeProvider theme={theme}>
      <Routes>
        <Route path="/:email/:otp/:setId/:mockId" element={<MainUserAuth />} />
        <Route
          path="/instructions"
          element={isUserAuth === true ? <Instructions /> : <ErrorPage errorMessage={"This link is not authorised."} />}
        />
        <Route
          path="/terms"
          element={isUserAuth === true ? <Terms /> : <ErrorPage errorMessage={"This link is not authorised."} />}
        />
        <Route
          path="/user_authentication"
          element={isUserAuth === true ? <UserAuth /> : <ErrorPage errorMessage={"This link is not authorised."} />}
        />
        <Route path="/mobileErrorPage" element={<MobileTemp />} />

        <Route
          path="/analysis/:mockId/:attemptId"
          element={isUserAuth === true ? <AnalysisMain /> : <ErrorPage errorMessage={"This link is not authorised."} />}
        >
          <Route path="topicwise" element={<TopicAnalysis />} />
          <Route path="subtopicwise" element={<SubtopicAnalysis />} />
          <Route path="sectionwise" element={<SectionAnalysis />} />
          <Route path="overall" element={<OverallAnalysis />} />
          <Route path="difficulty" element={<DifficultyAnalysis />} />
        </Route>
        <Route
          path="/leaderboard/:mockId/:attemptId"
          element={isUserAuth === true ? <LeaderBoard /> : <ErrorPage errorMessage={"This link is not authorised."} />}
        />
        <Route
          path="/viewsolutions/:mockId/:attemptId"
          element={isUserAuth === true ?isMobile?<ViewSolutionMob/>: <ViewSolution /> : <ErrorPage errorMessage={"This link is not authorised."} />}
        />
        <Route
          path="/main"
          element={
            isUserAuth === true ? (
              <Protected Comp={Main} />
            ) : (
              <ErrorPage errorMessage={"This link is not authorised."} />
            )
          }
        />
        <Route
          path="/analysisacross/:mockId/:attemptId"
          element={
            isUserAuth === true ? isMobile?<AnalysisAcrossMob/>: <AnalysisAcross /> : <ErrorPage errorMessage={"This link is not authorised."} />
          }
        />
        <Route
          path="/errortracker/:mockId/:attemptId"
          element={isUserAuth === true ?isMobile?<ErrorTrackerMob/> :<ErrorTracker /> : <ErrorPage errorMessage={"This link is not authorised."} />}
        />
        <Route
          path="/goaltracker/:mockId/:attemptId"
          element={
            isUserAuth === true ? <GoalTracker /> : <GoalTracker errorMessage={"This link is not authorised."} />
          }
        />
        <Route
          path="/marketplace/:mockId/:attemptId"
          element={isUserAuth === true ? <MarketPlace /> : <ErrorPage errorMessage={"This link is not authorised."} />}
        />
        <Route
          path="/mockcomparison/:mockId/:attemptId"
          element={
            isUserAuth === true ? <MockComparison /> : <ErrorPage errorMessage={"This link is not authorised."} />
          }
        />
        <Route
          path="/onboarding"
          element={isUserAuth === true ? <OnBoarding /> : <ErrorPage errorMessage={"This link is not authorised."} />}
        />
        <Route
          path="/scorevsprecentile/:mockId/:attemptId"
          element={
            isUserAuth === true ? isMobile?<ScoreVsPercentileMob/>:<ScoreVsPercentile /> : <ErrorPage errorMessage={"This link is not authorised."} />
          }
        />
        <Route path="/xatScoreCalculator" element={<ScoreCalculatorPage />} />
      </Routes>
    </ThemeProvider>
  );
}

export default App;
