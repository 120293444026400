import React, { useState, useEffect } from "react";
import { BootstrapButton, SubHeading } from "../styleSheets/Style";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getTermsAndConditions } from "../services/Mock_api";
import Latex from "react-latex-next";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { useSelector } from "react-redux";

function Terms() {
  const [agree, setAgree] = useState(false);
  const navigate = useNavigate();
  const { state } = useLocation();
  const [loading, setLoading] = useState(true);
  const [terms, setTerms] = useState();
  const {uid} = useSelector(state => state.userData);

  useEffect(() => {
    getTerms();
  }, []);

  const getTerms = async () => {
    try {
      const res = await getTermsAndConditions(state.mockId, uid);
      console.log(res);
      if (res.status == 200) {
        setTerms(res?.data?.data?.terms?.description);
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  // //console.log(params)
  return (
    <div className="container overflow-hidden d-flex-col justify-content-center align-content-center">
      <div className="logo container text-center my-4">
        <img
          src={require("../images/iQuanta.png")}
          className="img-fuid mx-auto"
          width={150}
          alt=""
        />
      </div>

      <div className="container mt-3 p-2 text-center">
        <SubHeading className="text-center">Terms & conditions</SubHeading>
        <div
          className="container m-2 p-4 mx-auto overflow-auto text-start"
          style={{
            border: "2px solid black",
            background: "var(--background-color)",
            height: "60vh",
          }}
        >
          {loading ? (
            <Box
              sx={{
                width: "100%",
                height: "100%",
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <Latex>{terms || ""}</Latex>
          )}
        </div>

        <div className="d-flex justify-content-center  align-self-center gap-5 ">
          <BootstrapButton
            variant="contained mx-auto"
            disabled={agree ? true : false}
            height="30"
            width="150"
            sx={{
              color: agree ? "black" : "white",
              p: 3,
              my: 2,
              borderRadius: "30px",
              backgroundColor: agree ? "#d2d4d6" : "",
            }}
            onClick={() => setAgree(true)}
          >
            Agree
          </BootstrapButton>
          <BootstrapButton
            variant="contained mx-auto"
            disabled={agree ? false : true}
            height="30"
            width="150"
            sx={{
              color: "white",
              p: 3,
              my: 2,
              borderRadius: "30px",
              background: agree ? "" : "#d2d4d6",
            }}
            onClick={() =>
              navigate("/user_authentication", {
                state: {
                  mockId: state.mockId,
                  setId: state.setId,
                },
              })
            }
          >
            Start Test
          </BootstrapButton>
        </div>
      </div>
    </div>
  );
}

export default Terms;
