import { Link, ListItemText, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { BootstrapButton, SubHeading } from "../styleSheets/Style";
import {
  UNSAFE_LocationContext,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { getInstructions } from "../services/Mock_api";
import Latex from "react-latex-next";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { useSelector } from "react-redux";
import ReactHTMLParser from "react-html-parser";
import DOMPurify from "dompurify";
function Instructions() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const { state } = useLocation();
  const [instructions, setInstructions] = useState();
  const {uid} = useSelector(state => state.userData);

  useEffect(() => {
    getInstruction();
  }, []);

  const getInstruction = async () => {
    try {
      const res = await getInstructions(state.mockId, uid);
      console.log(res,"31ins");
      if (res.status == 200) {
        setInstructions(res?.data?.data.instructions?.description);
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
    }
  };
   console.log("40",instructions);
  return (
    <div className="container overflow-hidden ">
      <div className="logo container text-center mt-4">
        <img
          src={require("../images/iQuanta.png")}
          className="img-fluid mx-auto "
          width={150}
          alt="IquantaLogo"
        />
      </div>

      <div className="container border-2 mt-4 p-2 text-center ">
        <SubHeading className="text-center"> Instructions</SubHeading>

        <div
          className="container m-2 p-4 mx-auto overflow-auto text-start"
          style={{
            border: "2px solid black",
            background: "var(--background-color)",
            height: "60vh",
          }}
        >
          {loading ? (
            <Box
              sx={{
                width: "100%",
                height: "100%",
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <HTMLConverter>{instructions}</HTMLConverter>
          )}
        </div>
        <div className="d-flex w-100 justify-content-center">
          <BootstrapButton
            width="150"
            height="30"
            variant="contained mx-auto"
            sx={{ color: "white", p: 3, my: 2, borderRadius: "30px" }}
            onClick={() =>
              navigate(`/terms`, {
                state: {
                  mockId: state.mockId,
                  setId: state.setId,
                },
              })
            }
          >
            Next
          </BootstrapButton>
          {/* <BasicModal /> */}
        </div>
      </div>
    </div>
  );
}

function HTMLConverter({ children }) {
  const sanitizedHtml = DOMPurify.sanitize(children);
  return <>{ReactHTMLParser(sanitizedHtml)}</>;
}

export default Instructions;
