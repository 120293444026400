import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { Typography } from "@mui/material";

function BarGraph({ Data, title, width, legend }) {
  //console.log(Data, title);

  return (
    <div
      className="shadow flex-item"
      style={{
        width: width,
        height: "auto",
        borderRadius: "15px",
      }}
    >
      <Typography
        sx={{
          fontFamily: "var(--inter)",
          fontWeight: 500,
          fontSize: "30px",
          color: "#434141",
          py: 3,
          textAlign: "center",
        }}
      >
        {title}
      </Typography>
      <ResponsiveContainer width="100%" height={300}>
        <BarChart
          data={[Data]}
          margin={{
            top: 5,
            right: 30,
            bottom: 15,
          }}
        >
          <CartesianGrid strokeDasharray="2 2" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          {legend && (
            <Legend layout="horizontal" verticalAlign="bottom" align="center" />
          )}

          <Bar dataKey="total" fill="url(#total)" radius={[10, 10, 0, 0]} />
          <Bar dataKey="incorrect" fill="#ff0000" radius={[10, 10, 0, 0]} />
          <Bar dataKey="correct" fill="#00E283" radius={[10, 10, 0, 0]} />
          {/* <Bar dataKey="partialCorrect" fill="#33ACFF" radius={[10, 10, 0, 0]} /> */}
          <Bar dataKey="skipped" fill="#B7B7B7" radius={[10, 10, 0, 0]} />
          
          <defs>
            <linearGradient id="total" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor="#8B30FF" />
              <stop offset="100%" stopColor="#B072FF" />
            </linearGradient>
            <linearGradient id="correct" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor="#685BFB" />
              <stop offset="100%" stopColor="#8C82FF" />
            </linearGradient>
            {/* <linearGradient id="partialCorrect" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor="#00C2FF" />
              <stop offset="100%" stopColor="#33ACFF" />
            </linearGradient> */}
            <linearGradient id="incorrect" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor="#00C2FF" />
              <stop offset="100%" stopColor="#BAE6FF" />
            </linearGradient>
            <linearGradient id="skipped" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor="#FF519A" />
              <stop offset="100%" stopColor="#FFAED0" />
            </linearGradient>
          </defs>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}

export default BarGraph;
