import React, { useEffect, useState } from "react";
import { ModifyButton } from "../styleSheets/Style";
import "../styleSheets/AnalysisMain.css";
import BarGraph from "../Components/BarGraph";
import PieChart from "../Components/PieChart";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import { useSelector } from "react-redux";

function DifficultyAnalysis() {
  const [data, setData] = useState([]);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { difficultyWiseAnalysis, sections } = useSelector((state) => state.analysisData);
  const [sectionIndex, setSectionIndex] = useState(0); // console.log(data);

  useEffect(() => {
    if (difficultyWiseAnalysis) {
      setData(difficultyWiseAnalysis[sectionIndex].data);
    }
  }, [difficultyWiseAnalysis, sectionIndex]);

  //console.log(show);
  //console.log(pieData);
  //console.log(activeButton);

  return (
    <div
      style={{
        width: "100%",
        padding: isSmallScreen ? "0px" : "2em",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          maxWidth: "100%",
          overflow: "scroll",
          gap: isSmallScreen ? "5px" : "10px",
          p: isSmallScreen ? 2 : 4,
        }}
      >
        {difficultyWiseAnalysis?.map((e, ind) => {
          return (
            <ModifyButton
              variant="filled"
              key={ind}
              onClick={() => setSectionIndex(ind)} // className={`${activeButton === "VARC" ? "active" : ""}`}
              sx={{
                p: 2,
                height: "35px",
                border: "2px solid transparent",
                minWidth: isSmallScreen ? "100px" : "200px",
                color: ind === sectionIndex ? "white" : "var( --gray-new)",
                fontWeight: "bold",
                background: ind === sectionIndex && "var( --gray-new)",
              }}
            >
              {e.name?.toUpperCase()}
            </ModifyButton>
          );
        })}
      </Box>
      <div className="d-flex flex-column justify-content-center mt-5 py-5">
        <div className="flex-item">
          <h1 className="text-center">Question Distributions</h1>
        </div>
        <hr
          className="mx-auto"
          style={{
            height: "2px",
            width: "50%",
            borderTop: "2px solid black",
            borderColor: "black!important",
          }}
        />
        <div className="flex-item flex-1">
          <PieChart
            data={data}
            // type={difficultyWiseAnalysis[sectionIndex]?.name}
          />
        </div>
      </div>
      <hr
        className="mx-auto"
        style={{
          height: "2px",
          width: "50%",
          borderTop: "2px solid black",
          borderColor: "black!important",
        }}
      />
      <h1 className="text-center pt-4 text-capitalize">{`${difficultyWiseAnalysis[sectionIndex]?.name} Analysis`}</h1>
      <div className="d-flex flex-row flex-wrap justify-content-between align-items-center mt-5 gap-3 mx-auto ">
        {/* Bar graphs */}

        {data?.map((item, ind) => {
          return (
            <div className="mx-auto">
              <BarGraph
                Data={item}
                key={ind}
                title={item.level?.charAt(0).toUpperCase() + item.level?.slice(1).toLowerCase() + " Questions"}
                width={ isSmallScreen?"70vw":"35vw"}
                legend={true}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default DifficultyAnalysis;
