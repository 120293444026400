import React from "react";
import HoverCard from "./HoverCard";
import { useParams } from "react-router-dom";
import { NavigationGalleryData } from "../services/DataFiles";
import { useTheme } from "@emotion/react";
import { useMediaQuery } from "@mui/material";

function NavigationGallery() {
  const params = useParams();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const CardDetails = NavigationGalleryData(params.mockId, params.attemptId);
  return (
    <>
      {isSmallScreen ? (
        <div className="row row-cols-1 row-gap-5">
          {CardDetails.map((item, ind) => {
            return (
              <div className="col">
                <HoverCard key={ind} image={item.image} heading={item.heading} path={item.path} />
              </div>
            );
          })}
        </div>
      ) : (
        <div className="row row-cols-1 row-cols-3 row-gap-5">
          {CardDetails.map((item, ind) => {
            return (
              <div className="col">
                <HoverCard key={ind} image={item.image} heading={item.heading} path={item.path} />
              </div>
            );
          })}
        </div>
      )}
    </>
  );
}

export default NavigationGallery;
