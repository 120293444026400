import { createSlice } from "@reduxjs/toolkit";

const analysisDataSlice = createSlice({
    name : "analysisData",
    initialState : {},
    reducers : {
        addBasicAnalysisData(state, action){
            return action.payload;
        },
        addMockTypeData(state, action){
             return {...state, ...action.payload}
        }
    }
});

export default analysisDataSlice.reducer;
export const {addBasicAnalysisData, addMockTypeData} = analysisDataSlice.actions;