import React, { useRef, useState } from "react";
import MenuDrawer from "../Components/MenuDrawer";
import HeaderNew from "../Components/HeaderNew";
import { typographyStyles } from "../styleSheets/StyleNew";
import { useTheme } from "@mui/material/styles";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Box, Typography, Card, CardContent, CardHeader } from "@mui/material";
import { LogoCard } from "../Common-comp/Card";
import { getMockComparison } from "../services/Analysis_api";
import { useEffect } from "react";
import { useParams } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import { getPrevMockData } from "../services/Analysis_api";
import ErrorPage from "./ErrorPage";
import { PuffLoader } from "react-spinners";
import Backdrop from "@mui/material/Backdrop";
import { useSelector } from "react-redux";
import InputLabel from "@mui/material/InputLabel";
import WindowPeriodFallback from "../Common-comp/windowPeriodFallback";
import { MobileViewCard } from "../Common-comp/CustomizedAccordian";

const ITEM_HEIGHT = 28;
const ITEM_PADDING_TOP = 3;
const MenuProps = {
  disableScrollLock: true,
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      maxWidth: 200,
    },
  },
};

function MockComparison() {
  const OuterCardStyle = {
    width: 246,
    height: "100%",
    ...typographyStyles.subHeading,
    fontSize: "15px",
    background: "#F5F9FF",
    boxShadow: "",
    borderRadius: "10px",
    boxShadow: "0px 4px 4px rgba(63, 105, 255, 0.25)",
    p: 1,
  };

  const innerCardStyle = {
    height: "auto",
    justifyContent: "space-around",
    iconSize: 25,
    display: "flex",
    flexDirection: "row-reverse",
    alignItems: "center",
    infoIcon: 20,
  };

  const ref = useRef(null);
  useEffect(() => {
    getData();
  }, []);
  const [isLoading, setLoading] = useState([]);
  const { mockId, attemptId } = useParams();
  const [result, setResult] = useState();
  const [prevMock, setPrevMock] = useState();
  const [topper, setTopper] = useState();
  const [prevMockList, setPrevMockList] = useState([]);
  const [isErr, setIsErr] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const { uid } = useSelector((state) => state.userData);
  const [optionIndex, setOptionIndex] = useState(0);
  const { isWindowPeriod } = useSelector((state) => state.analysisData);
  const { isMobile } = useSelector((state) => state.globalData);

  useEffect(() => {
    if (prevMockList) {
      setPrevMock(prevMockList[optionIndex]);
    }
  }, [optionIndex]);

  const getData = async () => {
    setLoading(true);
    try {
      const res = await getMockComparison(mockId, attemptId, uid);
      console.log(res);
      if (res?.status == 200) {
        setLoading(false);
        setResult(res.data?.data.result);
        setTopper(res.data?.data.topperResult);
        setPrevMockList(res.data?.data.previousMocks);
        setPrevMock(res.data?.data.previousMocks[0]);
      } else {
        console.log("some error occurred!!!");
      }
    } catch (err) {
      //console.log(err);
      setIsErr(true);
      setLoading(false);
      showToastMessage(err?.response?.data?.error);
    }
  };

  const showToastMessage = (obj) => {
    setErrorMsg(obj);
  };

  function ListedData(data) {
    return [
      { name: "Score", value: data?.score },
      {
        name: " Number of Questions Attempted",
        value: data?.attempted,
      },
      {
        name: " Number of Correct Questions Attempted",
        value: data?.attemptedCorrect,
      },
      {
        name: "  Number of Incorrect Questions Attempted",
        value: data?.attempted - data?.attemptedCorrect,
      },
      {
        name: "Number of Skipped Questions ",
        value: data?.skipped,
      },
      {
        name: "Average time spent per question",
        value: data?.avgTime + "(sec)",
      },
    ];
  }

  console.log(result, "result");
  console.log(topper, "topper");
  console.log(prevMock, "prevMock");
  return (
    <>
      <ToastContainer />{" "}
      <Box component="main">
        {!isMobile && <MenuDrawer />}

        {isWindowPeriod ? (
          <WindowPeriodFallback />
        ) : isErr == true ? (
          <ErrorPage error={errorMsg} />
        ) : (
          <Box
            sx={{
              position: "absolute",
              left:!isMobile && "65px",
              height: "100vh",
              width: { xs: "100%", sm: "100%", md: "calc(100% - 65px)" },
              p: 2,
              overflow: "scroll",
            }}
            ref={ref}
          >
            <Box component="header">
              <HeaderNew />
            </Box>

            {isLoading ? (
              <div
                className="d-flex align-items-center flex-column gap-2 justify-content-center"
                style={{ width: "100%", height: "80%" }}
              >
                <div class="loading-container">
                  <div class="loading"></div>
                  <div id="loading-text">Loading...</div>
                </div>
                <Typography sx={{ fontWeight: 500 }}>
                  It may take some longer, Please be patient!
                </Typography>
              </div>
            ) : (
              <Box
                component="section"
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-around",
                  mt: 3,
                }}
              >
                <Backdrop
                  sx={{
                    zIndex: (theme) => theme.zIndex.drawer - 1,
                    color: "#fff",
                  }}
                  // open={menuBarOpen}
                  // onClick={() => setMenuBarOpen(false)}
                />

                <Box
                  className="comparison-Section "
                  sx={{
                    p: 2,
                    display: { xs: "none", sm: "none", md: "block" },
                  }}
                >
                  <Typography
                    sx={{
                      ...typographyStyles.mainHeading,
                      pt: 2,
                    }}
                  >
                    {" "}
                    Mock <br /> Comparison
                  </Typography>

                  <ul
                    className="list-unstyled "
                    style={{
                      lineHeight: "4.4em",
                      ...typographyStyles.subHeading,
                      fontSize: "15px",
                      marginTop: "5px",
                    }}
                  >
                    <li>Score</li>
                    <li>Number of Questions Attempted</li>
                    <li>Number of Correct Questions Attempted</li>
                    <li>Number of Incorrect Questions Attempted</li>
                    <li>Number of Skipped Questions Attempted</li>
                    <li>Average time spent per question</li>
                  </ul>
                </Box>

                <Box
                  className="Cards  gap-5 align-items-center mt-4 "
                  sx={{
                    display: { xs: "none", sm: "none", md: "flex" },
                  }}
                >
                  <OuterCard
                    style={OuterCardStyle}
                    data={result}
                    miniCard={
                      <LogoCard
                        icon={"/click1.svg"}
                        infoIcon={"/circle-info-solid.svg"}
                        style={{ ...innerCardStyle }}
                        cardTitle={
                          <>
                            <Typography
                              variant="h4"
                              sx={{ fontSize: 17 }}
                              color={"black"}
                            >
                              {result?.percentile}{" "}
                              <span style={{ fontSize: "15px" }}>%ile</span>
                            </Typography>
                            <Typography sx={{ color: "#809FB8", fontSize: 15 }}>
                              {result?.title}
                            </Typography>
                            <Typography
                              sx={{ fontSize: "12px", fontWeight: 700 }}
                            >
                              {" "}
                              {"Current Mock"}
                            </Typography>{" "}
                          </>
                        }
                      />
                    }
                  />
                  {prevMockList?.length > 0 && (
                    <OuterCard
                      style={OuterCardStyle}
                      data={prevMock}
                      miniCard={
                        <LogoCard
                          style={{ ...innerCardStyle }}
                          icon={"/click1.svg"}
                          infoIcon={"/circle-info-solid.svg"}
                          select={
                            <SelectBox
                              setOptionIndex={setOptionIndex}
                              options={prevMockList}
                              optionIndex={optionIndex}
                            />
                          }
                          cardTitle={
                            <>
                              <Typography
                                variant="h4"
                                sx={{ fontSize: 17 }}
                                color={"black"}
                              >
                                {prevMock?.percentile}
                                <span style={{ fontSize: "15px" }}>%ile</span>
                              </Typography>
                              <Typography
                                sx={{ color: "#809FB8", fontSize: 15 }}
                              >
                                {prevMock?.title}
                              </Typography>
                            </>
                          }
                        />
                      }
                    />
                  )}
                  <OuterCard
                    data={topper}
                    miniCard={
                      <LogoCard
                        cardTitle={
                          <>
                            <Typography
                              variant="h4"
                              sx={{ fontSize: 17 }}
                              color={"black"}
                            >
                              {topper?.percentile}{" "}
                              <span style={{ fontSize: "15px" }}>%ile</span>
                            </Typography>
                            <Typography sx={{ color: "", fontSize: 15 }}>
                              {" "}
                              {topper?.title}
                            </Typography>
                            <Typography
                              sx={{ fontSize: "12px", fontWeight: 700 }}
                            >
                              {" "}
                              {"Topper Analysis"}
                            </Typography>
                          </>
                        }
                        style={{ ...innerCardStyle, background: "#FFECB9" }}
                        icon={"/top-rated.png"}
                        infoIcon={"/circle-info-solid.svg"}
                      />
                    }
                    style={{ ...OuterCardStyle, background: "#FFC107" }}
                  />
                </Box>

                {/* -------------------------------------- ||MOB VIEW CARDS  ||---------------------------------------------*/}
                <Box
                  component="div"
                  sx={{
                    mt: 2,
                    display: { xs: "flex", sm: "flex", md: "none" },
                    justifyContent: "center",
                    rowGap: 3,
                    height: "auto",
                    flexDirection: "column",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Typography
                    sx={{
                      ...typographyStyles.mainHeading,
                      textAlign: "start",
                    }}
                  >
                    {" "}
                    Mock Comparison
                  </Typography>

                  <MobileViewCard
                    data={result}
                    icon={"/click1.svg"}
                    infoIcon={"/circle-info-solid.svg"}
                    listData={ListedData(result)}
                  />

                  {prevMockList?.length > 0 && (
                    <MobileViewCard
                      data={prevMock}
                      listData={ListedData(prevMock)}
                      icon={"/click1.svg"}
                      infoIcon={"/circle-info-solid.svg"}
                      select={
                        <SelectBox
                          style={{ width: "400px" }}
                          setOptionIndex={setOptionIndex}
                          options={prevMockList}
                        />
                      }
                    />

                    
                  )}

                  <MobileViewCard
                    data={topper}
                    listData={ListedData(topper)}
                    icon={"/top-rated.png"}
                    infoIcon={"/circle-info-solid.svg"}
                    backgroundColor="#FFC107"
                    titleDescription="Topper Analysis"
                  />
                </Box>
              </Box>
            )}
          </Box>
        )}
      </Box>
    </>
  );
}

const OuterCard = ({ style, miniCard, data }) => {
  return (
    <Card sx={{ ...style }}>
      <Box component="div">{miniCard}</Box>
      <CardContent>
        <ul className="list-unstyled pt-4 text-center">
          <li>{data?.score}</li>
        </ul>
        <ul className="list-unstyled pt-4 text-center">
          <li>{data?.attempted}</li>
        </ul>
        <ul className="list-unstyled pt-4 text-center">
          <li>{data?.attemptedCorrect}</li>
        </ul>
        <ul className="list-unstyled pt-4 text-center">
          <li>{data?.attempted - data?.attemptedCorrect}</li>
        </ul>
        <ul className="list-unstyled pt-4 text-center">
          <li>{data?.skipped}</li>
        </ul>
        <ul className="list-unstyled pt-4 text-center">
          <li>{data?.avgTime}</li>
        </ul>
      </CardContent>
    </Card>
  );
};

const SelectBox = ({ options, optionIndex, setOptionIndex, style }) => {
  const theme = useTheme();
  //console.log(options);

  return (
    <div>
      <FormControl>
        <Select
          labelId="demo-multiple-name-label"
          id="demo-multiple-name"
          onChange={(e) => setOptionIndex(e.target.value)}
          sx={{
            height: "30px",
            width: "120px !important",
          }}
          MenuProps={MenuProps}
          value={optionIndex}
          defaultValue={"select"}
        >
          <MenuItem value="select" disabled>
            Select Mock
          </MenuItem>
          {options?.map((item, index) => (
            <MenuItem
              key={index}
              value={index}
              // style={getStyles(item._id, mockName, theme)}
            >
              {item.title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default MockComparison;
