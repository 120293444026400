import request from "./Request";

// api for fetching analysis data

export const fetchScore = async (link) => {
  
  try {
    const res = await request({
      url: `/api/student/connect/xat/xatCalculateMarks/link`,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      type: "POST",
      data: link 
    });
    return res;
  } catch (err) {
    console.error(err);
    return err;
  }
};