import * as React from "react";
import { createTheme } from "@mui/material/styles";
import { Box, Radio, RadioGroup } from "@mui/material";

const theme = createTheme({
  customVars: {
    radius: {
      md: 3,
    },
  },
  // other theme properties
});

function ExampleAlignmentButtons({ setValue, value, options, style }) {
  const styles = {
    button: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: style.buttonWidth,
      height: style.buttonHeight,

    
      "&:first-of-type": {
        borderTopLeftRadius: theme.customVars.radius.md,
        borderBottomLeftRadius: theme.customVars.radius.md,
      },
      "&:last-of-type": {
        borderTopRightRadius: theme.customVars.radius.md,
        borderBottomRightRadius: theme.customVars.radius.md,
      },
    },
    text: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around",
      width: style.buttonWidth,
      height: style.buttonHeight,
      transition: "border-color 0.3s ease",
      color: "black",
      fontFamily: "font(--font-inter)",
      fontWeight: 700,
      fontSize: 15,
    },
    checkedText: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: style.buttonWidth,
      height: style.buttonHeight,
      borderRadius: 10,
      backgroundColor: style.backgroundMain || "white",
      padding: 2,
      transition: "background-color 0.3s ease",
      color: "black",
      fontFamily: "font(--font-inter)",
      fontWeight: 700,
      fontSize: 15,
    },
  };

  return (
    <RadioGroup
      className="alignment-buttons"
      aria-label="Alignment"
      name="alignment"
      value={value}
      onChange={(event) => setValue(event.target.value)}
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        gap: 1,
        width: style.width,
        height: style.height,
        borderColor: theme.palette.divider,
        borderWidth: 1,
        borderStyle: "solid",
        background: style.highlightBackground || "white",
        borderRadius: theme.customVars.radius.md,
      }}
    >
      {options.map((item, index) => (
        <Box key={item.label} sx={styles.button}>
          <Radio
            value={item.label}
            disableRipple
            icon={<span style={styles.text}>{item.label}</span>}
            checkedIcon={<span style={styles.checkedText}>{item.label}</span>}
          />
        </Box>
      ))}
    </RadioGroup>
  );
}

export default ExampleAlignmentButtons;
