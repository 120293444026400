import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { typographyStyles } from "../styleSheets/StyleNew";
import { Stack, Paper } from "@mui/material";
import { HoverButton } from "../Components/HoverCard";
import { useNavigate } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 950,
  height: "auto",
  display: "flex",
  justifyContent: "space-around",
  columnGap: 2,
  bgcolor: "background.paper",
  borderRadius: 5,
  boxShadow: 24,
  border: "0px",
  p: 2,
};

export default function ETFallback({mockId,attemptId}) {
    const navigate=useNavigate();
  return (
    <div>
      <Box sx={style}>
        <img
          src="/wpImage.png"
          alt="This sections are not open"
          className="img-fluid"
          width={350}
          height={400}
        ></img>
        <div className="container p-5">
          <Typography
            id="transition-modal-description"
            sx={{ ...typographyStyles.mainHeading, color: "#000AFF" }}
          >
           You have not marked any errors yet to generate the report.
        </Typography>
          <Typography
            id="transition-modal-description"
            sx={{ ...typographyStyles.subHeading, color: "#000000", my: 2 }}
          >
           Please go to the "View Solution" tab, fill out the error tracker report according to the question, and then generate the report.
          </Typography>
          {/* <Typography
            id="transition-modal-description"
            sx={{ ...typographyStyles.subHeading, color: "#000000" }}
          >
            18th July 2023
          </Typography> */}
          <hr />
          
           <HoverButton sx={{bgcolor:"rgb(0, 10, 255)"}} variant="contained" onClick={()=>navigate(`/viewsolutions/${mockId}/${attemptId}`)}>
            Go to view solution
            </HoverButton> 
        </div>
      </Box>
    </div>
  );
}
