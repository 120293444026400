import React, { useEffect, useState } from "react";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
  StyledTableCell,
  StyledTable,
  StyledTableRow,
} from "../styleSheets/Style";
import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import Paper from "@mui/material/Paper";

function Table({ data }) {
  const { headings, body } = data;
  console.log(headings, "headings");
  console.log("body data from table", body);
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));
  return (
    <TableContainer
      sx={{
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        flexWrap: { sm: "wrap", md: "wrap", lg: "nowrap", xl: "nowrap" },
        maxHeight: "100%",
        maxWidth: "100%",
        overflowY: "scroll",
      }}
      component={Paper}
    >
      <StyledTable aria-label="customized table">
        <TableHead>
          <TableRow sx={{ background: "white", position: "sticky", top: 0 }}>
            {headings &&
              headings.map((item, _) => {
                return (
                  <StyledTableCell
                    sx={{ position: "sticky", top: 0, border: "none" }}
                    className="fw-bold"
                    align="center"
                  >
                    <Typography fontWeight={700} fontSize="16px">
                      {item.name}
                    </Typography>
                  </StyledTableCell>
                );
              })}
          </TableRow>
        </TableHead>
        <TableBody>
          {body?.map((item, index) => {
            return (
              <StyledTableRow
                key={index}
                sx={{
                  background: "white",
                  border: "none",
                  color: "black",
                }}
              >
                <StyledTableCell align="center">
                  <Typography fontWeight={700} fontSize={14}>
                    {item.percentile}
                  </Typography>
                </StyledTableCell>
                {headings.slice(1)?.map((e, ind) => (
                  <StyledTableCell key={ind} align="center">
                    <Typography fontWeight={700} fontSize={14}>
                      {item[e.name]}
                    </Typography>
                  </StyledTableCell>
                ))}
              </StyledTableRow>
            );
          })}
        </TableBody>
      </StyledTable>
    </TableContainer>
  );
}

export default Table;
