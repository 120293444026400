import Box from "@mui/material/Box";
import "../styleSheets/Calculator.css";
import Modal from "@mui/material/Modal";
import React, { useEffect } from "react";
import { SubHeading } from "./../styleSheets/Style";
import { MyButton } from "./../styleSheets/Style";
import { Typography } from "@mui/material";
import { useState } from "react";
import { useParams } from "react-router";
import { SubmitButton } from "../styleSheets/Style";
import { InfinitySpin } from "react-loader-spinner";
import { Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { submitSection } from "../services/Mock_api";
import { Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { submitAllSections, submitSectionWise } from "../services/Mock_api";
import { setCurrentSectionIndex } from "../store/slices/mockDataSlice";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 650,
  textAlign: "",
  height: 400,
  bgcolor: "white",
  borderRadius: "10px ",
  boxShadow: 24,
  p: 0,
  m: 0,
};

const ButtonSubmit = React.memo(() =>  {
  const buttonStyle = {
    background: "linear-gradient(91.59deg, #FD4153 18.67%, #F77A5B 98.68%)",
    width: "138px",
    color: "#fff",
    borderRadius: "20px",
  };
  const [isButtonDisabled, setButtonDisabled] = useState(true);
  const [isModalOpen, setModalOpen] = useState(false);
  const [state, setState] = useState(0);
  const params = useParams();
  const navigate = useNavigate();
  const [done, setDone] = useState(false);
  const {
    attemptId,
    mockId,
    studentResponse,
    isToggleAllowed,
    currentSectionIndex,
  } = useSelector((state) => state.mockData);
  const { uid } = useSelector((state) => state.userData);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isToggleAllowed) {
      setButtonDisabled(false);
    } else {
      if (currentSectionIndex < studentResponse.length - 1) {
        setButtonDisabled(true);
      } else {
        setButtonDisabled(false);
      }
    }
  }, [currentSectionIndex]);

  const submitResponseFunc = async () => {
    
    if (isToggleAllowed) {
      try {
        setState(1);
        const studentResponseArr = studentResponse.map((section) => {
          const answers = section.questions.map((obj) => {
            return {
              questionId: obj.questionId._id,
              studentAnswer: obj?.studentAnswer,
              studentAnswerIndex: obj.studentAnswerIndex,
              studentAnswerMulti:obj.studentAnswerMulti,
              duration: obj?.duration,
              stage: obj?.stage,
            };
          });
          return {
            section: section.section,
            questions: answers,
          };
        });
        console.log(studentResponseArr);
        const response = await submitAllSections(
          mockId,
          attemptId,
          studentResponseArr,
          uid
        );
        console.log(response);
        if (response.status == 200) {
          setState(2);
        } else {
          setState(3);
          console.log("some error occurred");
        }
      } catch (err) {
        console.log(err);
        setState(3);
      }
    } else {
      try {
        setState(3);
        const { questions, section } = studentResponse[currentSectionIndex];
        const studentResponseArr = questions.map((e) => {
          return {
            questionId: e.questionId._id,
            studentAnswer: e?.studentAnswer,
            studentAnswerIndex:
              e?.studentAnswerIndex === undefined || null
                ? null
                : e?.studentAnswerIndex,
            duration: e?.duration,
            studentAnswerMulti:e?.studentAnswerMulti,
            stage: e?.stage,
          };
        });
        const response = await submitSectionWise(
          mockId,
          attemptId,
          section,
          studentResponseArr,
          uid
        );
        console.log(response);
        if (response.status == 200) {
          if (currentSectionIndex < studentResponse.length - 1) {
            dispatch(setCurrentSectionIndex(currentSectionIndex + 1));
          } else {
            setState(2);
          }
        }
      } catch (err) {
        console.log(err);
        setState(3);
        //setErr(true);
      }
    }
  };
  console.log(isButtonDisabled, "isButton Disabled");
  console.log(isModalOpen, "is Modal Open")
  return (
    <span>
      <SubmitButton
        sx={{
          width: "96%",
          marginTop: "1em",
          backgroundColor: "#EBEBEB",
          ":disabled": {
            background: "#D9D9D9",
            color: "white",
          },
        }}
        disabled={isButtonDisabled}
        variant="contained"
        onClick={() => setModalOpen(true)}
      >
        Submit
      </SubmitButton>
      {/* Confirm modal */}
      <Modal
        open={isModalOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {state === 0 ? (
            <>
              <div className="d-flex justify-content-center">
                <Box
                  component="img"
                  sx={{
                    height: 233,
                    width: 600,
                  }}
                  alt="no IMage"
                  src="/ModalImage.png"
                />
              </div>
              <div className="d-flex justify-content-center">
                <SubHeading
                  style={{ color: "#494949", fontWeight: "700" }}
                  className="ps-3"
                >
                  Are you sure to submit your test?{" "}
                </SubHeading>
              </div>
              <div
                className="d-flex justify-content-evenly"
                style={{ marginTop: "1.8em" }}
              >
                <MyButton
                  variant="contained"
                  sx={{
                    bgcolor: "#EBEBEB",
                    color: "black",
                    borderRadius: "20px",
                    ":hover": { background: "#EBEBEB", color: "black" },
                  }}
                  onClick={() => setModalOpen(false)}
                >
                  Have a doubt? Back to test
                </MyButton>
                <MyButton
                  variant="contained"
                  style={buttonStyle}
                  onClick={() => submitResponseFunc()}
                >
                  Submit
                </MyButton>
              </div>
            </>
          ) : state === 1 ? (
            <>
              {" "}
              <div
                style={{ marginTop: "3em" }}
                className="d-flex justify-content-center"
              >
                <SubHeading className="m-4 ps-3">
                  Submitting Test...{" "}
                </SubHeading>
              </div>
              <div
                className="d-flex justify-content-center"
                style={{ marginTop: "1em" }}
              >
                <div style={{ marginLeft: "12px" }}>
                  {" "}
                  <InfinitySpin color="blue" />
                </div>
              </div>
              <div className="d-flex justify-content-center mt-4 ">
                <Typography>Please Wait...</Typography>
              </div>
            </>
          ) : state === 2 ? (
            <>
              {" "}
              <div
                className="d-flex justify-content-center"
                style={{ height: "50%", width: "100%" }}
              >
                <div
                  style={{
                    height: "100%",
                    backgroundColor: "#0075FF",
                    width: "100%",
                    borderTopLeftRadius: "10px ",
                    borderTopRightRadius: "10px ",
                  }}
                  className="d-flex justify-content-center align-items-center"
                >
                  <Image
                    className="img-fluid text-center ps-4 "
                    src="/Group103.png"
                    alt="no IMage"
                    width={300}
                  ></Image>
                </div>
              </div>
              <div className="d-flex justify-content-center ">
                <SubHeading style={{ fontWeight: "800" }} className="m-4 ps-3">
                  Thank You!{" "}
                </SubHeading>
              </div>
              <div className="d-flex justify-content-center text-muted">
                <Typography fontWeight={700}>
                  Congrats! You have completed the Mock test
                </Typography>
              </div>
              <div
                className="d-flex justify-content-center"
                style={{ marginTop: "1em" }}
              >
                <MyButton
                  variant="contained"
                  sx={{
                    ...buttonStyle,
                    background:
                      " linear-gradient(90.38deg, #2400FF 5.86%, #725BFF 99.82%)",
                    borderRadius: "30px",
                  }}
                  onClick={() => {
                    setDone(true);
                    navigate(`/analysis/${mockId}/${attemptId}/overall`);
                  }}
                  disabled={done}
                >
                  DONE
                </MyButton>
              </div>
            </>
          ) : state === 3 ? (
            <>
              <div
                style={{ marginTop: "3em" }}
                className="d-flex justify-content-center"
              >
                <SubHeading className="m-4 ps-3">
                  Section Submitting...{" "}
                </SubHeading>
              </div>
              <div
                className="d-flex justify-content-center"
                style={{ marginTop: "1em" }}
              >
                <div style={{ marginLeft: "12px" }}>
                  {" "}
                  <SubHeading style={{ color: "red" }} className="m-4 ps-3">
                    Some Error Occurred!!!{" "}
                  </SubHeading>
                </div>
              </div>
              <div className="d-flex justify-content-center mt-4 ">
                <Button onClick={() => window.location.reload()}>
                  Try Again
                </Button>
              </div>
            </>
          ) : (
            ""
          )}
        </Box>
      </Modal>
    </span>
  );
});

export default ButtonSubmit;
