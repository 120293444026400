import React from "react";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import NavigationGallery from "../Components/NavigationGallery";
import { Typography, useMediaQuery } from "@mui/material";
import { useSelector } from "react-redux";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled, useTheme } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import Box from "@mui/material/Box";

function OverallAnalysis() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { overallAnalysis, isWindowPeriod } = useSelector((state) => state.analysisData);
  const headings = [
    "Name",
    "Questions",
    "Attempted",
    "Correct",
    "Incorrect",
    //"Partial Correct",
    "Score",
    "Accuracy",
    "% Score",
    "Percentile",
  ];
  const convertStoMs = (seconds) => {
    let minutes = Math.floor(seconds / 60);
    let extraSeconds = seconds % 60;
    minutes = minutes < 10 ? +minutes : minutes;
    extraSeconds = extraSeconds < 10 ? +extraSeconds : extraSeconds;
    //console.log(minutes, extraSeconds);
    return `${minutes + "." + extraSeconds + " min"}`;
  };
  // convertStoMs(70);
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#4d4d4d",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  return (
    <Box sx={{ padding: 1, borderRadius: 10 }}>
      <TableContainer
        sx={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          flexDirection:{xs:"column",sm:"column",md:"row"},
          gap:2,
          p:1,
          overflow:"scroll"
          // flexWrap: {xs:"wrap", sm: "wrap", md: "wrap", lg: "nowrap", xl: "nowrap" },
        }}
        component={Paper}
      >
        <Table  sx={{ width: {xs:"100%",sm:"100%",md:"75%"}, }}   aria-label="customized table">
          <TableHead>
            <TableRow>
              {headings.map((heading, ind) => {
                return (
                  <StyledTableCell align="left" key={ind} className="fw-bold py-4" sx={{ fontSize: "16px" }}>
                    {heading}
                  </StyledTableCell>
                );
              })}
            </TableRow>
          </TableHead>

          <TableBody>
            {overallAnalysis?.slice(1)?.map((item, index) => {
              return (
                <StyledTableRow
                  key={index}
                  sx={{
                    background: "white",
                    border: "none",
                    color: "black",
                    fontWeight: 700,

                  }}
                >
                  <StyledTableCell align="left" className="fw-bold">
                    <Typography sx={{ fontSize: "14px" }} fontWeight="700">
                      {item.name}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <Typography sx={{ fontSize: "14px" }}>{item.questions}</Typography>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <Typography sx={{ fontSize: "14px" }}>{item.attempted}</Typography>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <Typography sx={{ fontSize: "14px" }}>{item.correct}</Typography>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <Typography sx={{ fontSize: "14px" }}>{item.incorrect}</Typography>
                  </StyledTableCell>
                  {/* <StyledTableCell align="left">
                    <Typography sx={{ fontSize: "14px" }}>
                      {item.partialCorrect}
                    </Typography>
                  </StyledTableCell> */}
                  <StyledTableCell align="left">
                    <Typography sx={{ fontSize: "14px" }}>{item.score}</Typography>
                  </StyledTableCell>
                  <StyledTableCell align="left" sx={{ color: "#0C58B6" }}>
                    <Typography sx={{ fontSize: "14px" }}>{item.accuracy}</Typography>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <Typography sx={{ fontSize: "14px" }}>
                      {item.perScore < 0 ? 0 + " %" : item.perScore + " %"}
                      {/* {item.perScore +  " %"} */}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell align="left" sx={{ color: "#0C58B6" }}>
                    <Typography sx={{ fontSize: "14px" }}>{item.percentile ? item.percentile : "N/A"}</Typography>
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
        {/* 2nd table  side table */}
        {(
          <Table sx={{ width: {xs:"100%",sm:"100%",md:"25%"}, ml: 2 }} aria-label="customized table">
            <TableHead>
              <TableRow sx={{ background: "white", borderBottom: "none" }}>
                <StyledTableCell className="fw-bold py-4" align="left ">
                  <Typography fontWeight={700} fontSize="16px">
                    Correct
                  </Typography>
                </StyledTableCell>
                <StyledTableCell className="fw-bold py-4" align="left">
                  <Typography fontWeight={700} fontSize="16px">
                    Incorrect
                  </Typography>
                </StyledTableCell>
                <StyledTableCell className="fw-bold py-4" align="left">
                  <Typography fontWeight={700} fontSize="16px">
                    Skipped
                  </Typography>
                </StyledTableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {overallAnalysis?.slice(1)?.map((item, index) => {
                return (
                  <StyledTableRow
                    key={index}
                    sx={{
                      background: "white",
                      border: "none",
                    }}
                  >
                    <StyledTableCell align="left">
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: 500,
                          color: "#0C58B6",
                        }}
                      >
                        {convertStoMs(item.timeCorrect)}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {" "}
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: 500,
                          color: "#0C58B6",
                        }}
                      >
                        {convertStoMs(item.timeInCorrect)}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {" "}
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: 500,
                          color: "#0C58B6",
                        }}
                      >
                        {convertStoMs(item.timeSkipped)}
                      </Typography>
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </Table>
        )}
      </TableContainer>

      <div className="container-fluid my-5">
        <NavigationGallery />
      </div>
    </Box>
  );
}

export default OverallAnalysis;
