import * as React from "react";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Skeleton from "@mui/material/Skeleton";
import {
  StyledTableCell,
  StyledTable,
  StyledTableRow,
} from "../styleSheets/Style";
import { Box } from "@mui/material";

const SkeletonRows = () => {
  return (
    <>
      {[...Array(10)].map((_, index) => (
        <StyledTableRow key={index} sx={{ background: "white" }}>
          {[...Array(7)].map((_, i) => (
            <StyledTableCell key={i}>
              <Skeleton />
            </StyledTableCell>
          ))}
        </StyledTableRow>
      ))}
    </>
  );
};

export default function LeaderTable({
  leaderList,
  isLoading,
  leaderListKeys,
  userData,
}) {
  console.log(leaderList, "LeaderList");
  console.log(leaderListKeys, "LeaderListKeys");
  console.log(userData, "userData");

  return (
    <TableContainer component="div">
      <StyledTable sx={{ minWidth: "100vw" }} aria-label="simple table">
        <TableHead>
          <StyledTableRow
            sx={{
              background: "white",
              "&:hover": { background: "none!important" },
            }}
          >
            {leaderListKeys?.map((e, ind) => {
              return (
                <StyledTableCell key={ind} className="fw-bold" align="left">
                  {e.name}
                </StyledTableCell>
              );
            })}
          </StyledTableRow>
          <StyledTableRow
            sx={{
              backgroundColor: "#E1E2FE",
              "&:hover": { background: "#E1E2FE!important" },
              boxShadow: 6,
            }}
          >
            {isLoading ? (
              [...Array(7)].map((_, i) => (
                <StyledTableCell key={i}>
                  <Skeleton />
                </StyledTableCell>
              ))
            ) : (
              <>
                {leaderListKeys?.map((e, ind) => (
                  <StyledTableCell className="fw-bold" sx={{ fontSize: 16 }}>
                    {userData?.[e.name]}
                  </StyledTableCell>
                ))}
              </>
            )}
          </StyledTableRow>
        </TableHead>
        <TableBody>
          <Box style={{ minHeight: "3px" }}></Box>
          {isLoading ? (
            <SkeletonRows />
          ) : (
            <>
              {leaderList?.map((item, index) => (
                <StyledTableRow
                  key={index}
                  sx={{
                    background:
                      index == 0
                        ? "#FF7A00"
                        : index == 1
                        ? "#4B87FC"
                        : index == 2
                        ? "#B53FFD"
                        : "white",
                    color: "black",
                  }}
                >
                  {leaderListKeys?.map((e, ind) => (
                    <StyledTableCell key={ind} component="th" scope="row">
                      {item?.[e.name]}
                    </StyledTableCell>
                  ))}
                </StyledTableRow>
              ))}
            </>
          )}
        </TableBody>
      </StyledTable>
    </TableContainer>
  );
}
