import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { BootstrapTooltip, ModifyButton } from "../../styleSheets/Style";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import Zoom from "@mui/material/Zoom";
import { Paper, Popover, Card, Select, MenuItem, OutlinedInput } from "@mui/material";
import Radio from "@mui/material/Radio";
import { Checkbox } from "@mui/material";
import RadioGroup from "@mui/material/RadioGroup";
import Latex from "react-latex-next";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { FormGroup } from "@mui/material";
import HeaderNew from "../../Components/HeaderNew";
import { fetchViewSolution } from "../../services/Analysis_api";
import { LogoButton } from "../../Common-comp/Buttons";
import { TempCompo,MiniTempCompo } from "../../Components/tempCompo";
import Modal from "@mui/material/Modal";
import { postToErrorTracker } from "../../services/Analysis_api";
import { BsChevronDoubleRight } from "react-icons/bs";
import ErrorPage from ".././ErrorPage";

import {
  IncorrectDetailing,
  CorrectDetailing,
  SkippedDetailing,
} from "../../services/DataFiles";
import Button from "@mui/material/Button";
import { ToastContainer, toast } from "react-toastify";
import { useSelector } from "react-redux";
import WindowPeriodFallback from "../../Common-comp/windowPeriodFallback";
import CustomEdgeDrawer from "../../Common-comp/swipeableComp";


export default function ViewSolution() {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(true);
  const { attemptId, mockId } = useParams();
  const [data, setData] = useState([]);

  const [show, setShow] = useState([]);
  // const [index, setIndex] = useState(null);
  const [open, setOpen] = useState(false);
  const handleOpenModal = () => setOpen(true);
  const handleCloseModal = () => setOpen(false);
  const [viewSol, setViewSoln] = useState(false);
  const [errData, setErrData] = useState([]);
  const [errTracker, setTracker] = useState([]);
  const [errValue, setErrValue] = useState("");
  const { state } = useLocation();
  const [isVideo, setVideo] = useState();
  const ref = useRef(null);
  const [popoverAnchorEl, setPopoverAnchorEl] = React.useState(null);
  const [isErr, setIsErr] = useState(false);
  const [error, setError] = useState();
  const { uid } = useSelector((state) => state.userData);
  const { isWindowPeriod } = useSelector((state) => state.analysisData);
  const [sectionIndex, setSectionIndex] = useState(0);
  const [questionIndex, setQuestionIndex] = useState(0);

  const handlePopoverOpen = (event) => {
    setPopoverAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setPopoverAnchorEl(null);
  };

  const popOpen = Boolean(popoverAnchorEl);
  const ITEM_HEIGHT = "48";
  const ITEM_PADDING_TOP = 3;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      },
    },
  };

  const showToastMessage = (obj) => {
    setIsErr(true);
    setError(obj);
  };
  //console.log("data", data);
  //console.log("open", open);
  //console.log("index", index);

  // //console.log(data);
  // //console.log(open);
  //console.log("show", show[index], index);

  // getting a specific question on mounting

  useEffect(() => {
    const func = async () => {
      if (state !== null) {
        console.log("flow2");
        const questionId = state.questionId;
        console.log(questionId);
        setLoading(true);
        const res = await fetchViewSolution(attemptId, mockId, uid);
        console.log(res);
        if (res?.status == 200) {
          const arr = res.data.data.sectionList;
          setData(arr);
          setErrData(res.data.data?.errorData?.sections);
          arr?.forEach((item, sectionInd) => {
            item?.data.forEach((e, questionIndex) => {
              if (e.question.questionId._id === questionId) {
                console.log(sectionInd);
                console.log(questionIndex);
                setSectionIndex(sectionInd);
                setQuestionIndex(questionIndex);
                setShow(arr[sectionInd]?.data);
                return;
              }
            });
          });
          setLoading(false);
        }
      }
    };

    func();
  }, []);

  // function getting data on mounting
  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      try {
        const res = await fetchViewSolution(attemptId, mockId, uid);
        console.log(res);
        if (res?.status == 200) {
          setData(res.data.data.sectionList);
          setShow(res.data.data.sectionList[sectionIndex]?.data);
          setErrData(res.data.data?.errorData?.sections);
          setTracker(
            res.data.data?.errorData?.sections?.[sectionIndex]?.questions
          );
          setLoading(false);
        } else {
          setLoading(false);
          showToastMessage();
          //console.log("error", res);
        }
      } catch (err) {
        console.log(err);
        setLoading(false);
        //console.log(err?.response?.data?.msg);
        showToastMessage(err?.response?.data?.error);
      }
    };
    if (state === null) {
      getData();
    }
  }, []);
  console.log(show, "show");
  console.log(data, "data");

  // Changing sectionwise data

  useEffect(() => {
    setTracker(errData?.[sectionIndex]?.questions);
    setShow(data?.[sectionIndex]?.data);
    setViewSoln(false);
    setQuestionIndex(0);
    return;
  }, [sectionIndex]);

  const buttonStyle = {
    background: "var(--blue-new)",
    color: "white",
    width: "auto",
    height: 37,
    borderRadius: "10.44px",
    fontSize: "9px",
    fontWeight: "500",
    fontFamily: "var(--font-inter)",
    iconSize: 13,
    p: 2,
  };
  const style = {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90vw",
    textAlign: "",
    height: "50vh",
    bgcolor: "white",
    borderRadius: "10px ",
    boxShadow: 24,
    backroundSize: "cover",
    backgroundRepeat: "no-repeat",
    objectfit: "cover",
  };

  const handleErrorForm = async (e) => {
    console.log(e.target.value);
    setErrValue(e.target.value);
    const selectedObj = show[questionIndex];
    console.log("slwlwlwlwl", selectedObj);

    const payload = {
      _id: attemptId,
      uid: uid,
      entityId: selectedObj.question.questionId.entity_type_id,
      mockId: mockId,
      sectionName: data[sectionIndex].name,
      sectionId: data[sectionIndex].sectionId,
      questionId: selectedObj.question.questionId._id,
      level: selectedObj.question.questionId.difficulty_level_manual,
      topic: selectedObj.question.questionId.topic_id,
      subtopic: selectedObj.question.questionId.subtopic_id,
      tracker: e.target.value,
      response:
        selectedObj.question.questionId.answer ===
        selectedObj.question.studentAnswer
          ? "correct"
          : selectedObj.question.stage === 0 ||
            selectedObj.question.stage === 2 ||
            selectedObj.question.stage === 3
          ? "skipped"
          : "incorrect",
    };
    //console.log(payload);
    const res = await postToErrorTracker(attemptId, payload, uid);
    //console.log(res);
    if (res?.status == 200) {
      //console.log(index);
      const tempObj = {
        questionId: selectedObj?.question.questionId._id,
        level: selectedObj?.question.questionId.difficulty_level_manual,
        tracker: e.target.value,
      };
      let arr = [...errTracker];
      if (questionIndex < arr.length) {
        console.log("if block is working");
        arr.splice(questionIndex, 1, tempObj);
      } else {
        arr[questionIndex] = tempObj;
      }
      setTracker(arr);
    }
  };

  // making state empty after question change
  useEffect(() => {
    const tempObj = errTracker?.[questionIndex];
    if (tempObj?.tracker == null) {
      setErrValue("");
    } else {
      setErrValue(tempObj?.tracker);
    }
  }, [errTracker, questionIndex]);

  // //console.log(selected);
  const [errorOptions, setErrorOptions] = useState([]);

  //For changing  the options error card
  useEffect(() => {
    if (show && show[questionIndex]) {
      const options =
        show[questionIndex]?.question.stage === 0 ||
        show[questionIndex]?.question.stage === 2 ||
        show[questionIndex]?.question.stage === 3 // 0 ,2 or 3 stage for skipped
          ? SkippedDetailing
          : (show[questionIndex]?.question.stage === 1 ||
              show[questionIndex]?.question.stage === 4) &&
            show[questionIndex]?.question.studentAnswer ===
              show[questionIndex]?.question.questionId.answer // 1 or 4 for attempted
          ? CorrectDetailing
          : IncorrectDetailing;
      setErrorOptions(options.slice(1));
    }
    //console.log("datatattaftaf", errorOptions);
  }, [questionIndex, show]);
  const bottomRef = useRef(null);

  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [viewSol]);

  console.log(errTracker, "errTracker");
  console.log(errData, "errData");
  console.log(show, "show");
  console.log("🚀 ~ ViewSolution ~ data:", data);

  return (
    <>
      {isWindowPeriod ? (
        <WindowPeriodFallback />
      ) : (
        <Box sx={{ p: 2 }}>
          {isErr == true ? (
            <ErrorPage error={error} />
          ) : (
            <Box component="main" sx={{ height: "100%" }} ref={ref}>
              <Box component="div" sx={{ height: "10%" }}>
                <HeaderNew />
              </Box>
              {isLoading ? (
                <div
                  className="d-flex align-items-center flex-column gap-2 justify-content-center"
                  style={{ width: "100%", height: "auto", marginTop: "auto" }}
                >
                  <div class="loading-container">
                    <div class="loading"></div>
                    <div id="loading-text">Loading...</div>
                  </div>
                </div>
              ) : (
                <>
                  {/* Navigation bar end */}


                  <Box
                    component="div"
                    sx={{
                      display: "flex",
                      gap: 1,
                      flexWrap: "wrap",
                      mt: "1em",
                      pb: 10,
                    }}
                  >
                    <div>
                      {/* <MultipleSelect options={Subjects} setType={setSelected} /> */}
                      <FormControl
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          columnGap: 3,
                        }}
                      >
                        <Select
                          defaultValue={sectionIndex}
                          onChange={(e) => setSectionIndex(e.target.value)}
                          input={
                            <OutlinedInput
                              sx={{
                                width: 127,
                                borderRadius: 2,
                                height: 32,
                                fontSize: "12px",
                                fontWeight: 700,
                                fontFamily: "var(--font-inter)",

                                ".MuiOutlinedInput-notchedOutline": {
                                  border: 1,
                                  borderColor: "#809EB9",
                                },
                                "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                  {
                                    border: 1,
                                    borderColor: "#809EB9",
                                  },
                                "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                  {
                                    border: 2,
                                    borderColor: "#809EB9",
                                  },
                              }}
                            />
                          }
                          MenuProps={MenuProps}
                          inputProps={{ "aria-label": "Select value" }}
                        >
                          <MenuItem value={""} disabled>
                            <em>Select</em>
                          </MenuItem>
                          {data?.map((item, ind) => (
                            <MenuItem
                              key={ind}
                              value={ind}
                              sx={{
                                fontFamily: "var(--font-inter)",
                                fontSize: "11px",
                                fontWeight: "600",
                              }}
                            >
                              {item.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                    {/*------------------------------------|LEFT Main start|-------------------------------- */}
                    <div
                      className="flex-column gap-3 "
                      style={{
                        width: "100%%",
                        display: "flex",
                        boxShadow: 3,
                        border: "none",
                        borderRadius: 5,
                        height: "100%",
                      }}
                    >
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          boxShadow: 3,
                          border: "none",
                          borderRadius: 5,
                          height: "78%",
                          flexWrap: "wrap",
                        }}
                        component={Paper}
                      >
                        <Box
                          component="div"
                          display={
                            show &&
                            show[questionIndex]?.question.questionId?.isPara ===
                              true
                              ? "block"
                              : "none"
                          }
                          sx={{
                            // flexBasis: "60%",
                            textAlign: "justify",
                            height: "100%",
                            overflow: "scroll",
                            p: 3,
                          }}
                        >
                          <Latex>
                            {(show &&
                              show[questionIndex]?.question.questionId
                                ?.context) ||
                              ""}
                          </Latex>
                        </Box>

                        <Box
                          component="div"
                          sx={{
                            // flexBasis:
                            //   show &&
                            //   show[questionIndex]?.question.questionId
                            //     ?.isPara === true
                            //     ? "40%"
                            //     : "100%",
                            textAlign: "justify",
                            height: "100%",
                            overflow: "scroll",
                            p: 3,
                          }}
                        >
                          <Typography fontWeight={600} variant="h6">
                            QUESTION . {questionIndex + 1}
                          </Typography>
                          <div>
                            <Typography variant="paragraph">
                              <Latex>
                                {(show &&
                                  show?.[questionIndex]?.question.questionId
                                    ?.question) ||
                                  ""}
                              </Latex>
                            </Typography>
                          </div>
                          <div>
                            {show?.[questionIndex]?.question.questionId
                              ?.questionType == "scq" ? (
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: 2,
                                }}
                              >
                                {show?.length > 0 &&
                                  show[
                                    questionIndex
                                  ].question.questionId?.options?.map(
                                    (item, ind) => (
                                      <FormControlLabel
                                        key={ind}
                                        checked={
                                          show && viewSol
                                            ? item ===
                                              show[questionIndex]?.question
                                                .questionId.answer
                                              ? true
                                              : item ===
                                                show[questionIndex]?.question
                                                  .studentAnswer
                                              ? true
                                              : false
                                            : false
                                        }
                                        control={
                                          <Radio
                                            color={
                                              show && viewSol
                                                ? item ===
                                                  show[questionIndex]?.question
                                                    .questionId.answer
                                                  ? "success"
                                                  : item ===
                                                    show[questionIndex]
                                                      ?.question.studentAnswer
                                                  ? "error"
                                                  : "default"
                                                : "default"
                                            }
                                            disableRipple
                                          />
                                        }
                                        label={
                                          <Typography
                                            color={
                                              show && viewSol
                                                ? item ===
                                                  show[questionIndex]?.question
                                                    .questionId.answer
                                                  ? "#63B31E"
                                                  : item ===
                                                    show[questionIndex]
                                                      ?.question.studentAnswer
                                                  ? "#E94504"
                                                  : "black"
                                                : "black"
                                            }
                                          >
                                            <Latex>{item || ""}</Latex>
                                          </Typography>
                                        }
                                      />
                                    )
                                  )}
                              </Box>
                            ) : show?.[questionIndex]?.question.questionId
                                ?.questionType == "mcq" ? (
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: 2,
                                }}
                              >
                                {show?.length > 0 &&
                                  show[
                                    questionIndex
                                  ].question.questionId?.options?.map(
                                    (item, ind) => (
                                      <FormControl
                                        sx={{ m: 1 }}
                                        component="fieldset"
                                        variant="standard"
                                      >
                                        {/* <FormLabel component="legend">"Test Multi Select"</FormLabel> */}
                                        <FormGroup>
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                checked={
                                                  show &&
                                                  show[
                                                    questionIndex
                                                  ]?.question.studentAnswerMulti.includes(
                                                    item
                                                  )
                                                }
                                              />
                                            }
                                            label={
                                              <Typography
                                                color={
                                                  show &&
                                                  viewSol &&
                                                  show[
                                                    questionIndex
                                                  ]?.question.studentAnswerMulti.includes(
                                                    item
                                                  ) &&
                                                  !show[
                                                    questionIndex
                                                  ]?.question.questionId.answerMulti?.includes(
                                                    item
                                                  )
                                                    ? "red" //green
                                                    : show &&
                                                      viewSol &&
                                                      show[
                                                        questionIndex
                                                      ]?.question.questionId.answerMulti?.includes(
                                                        item
                                                      )
                                                    ? "green" //red
                                                    : "black"
                                                }
                                              >
                                                <Latex>{item || ""}</Latex>
                                              </Typography>
                                            }
                                          />
                                        </FormGroup>
                                      </FormControl>
                                    )
                                  )}
                              </Box>
                            ) : (
                              <>
                                {" "}
                                <Typography color="black" fontWeight={600}>
                                  Your Answer :{" "}
                                  {show &&
                                  show?.[questionIndex]?.question
                                    ?.studentAnswer ==
                                    (null || undefined || "") ? (
                                    "NA"
                                  ) : (
                                    <Latex>
                                      {show?.[questionIndex]?.question
                                        ?.studentAnswer || ""}
                                    </Latex>
                                  )}
                                </Typography>
                                <Typography
                                  marginTop={2}
                                  color="green"
                                  fontWeight={600}
                                >
                                  Correct Answer :{" "}
                                  {
                                    <Latex>
                                      {(show &&
                                        viewSol &&
                                        show?.[questionIndex]?.question
                                          ?.questionId.answer) ||
                                        ""}
                                    </Latex>
                                  }
                                </Typography>
                              </>
                            )}
                          </div>
                          <Box
                            component="div"
                            sx={{
                              display: "flex",
                              justifyContent: "space-around",
                              flexWrap: "wrap",
                              rowGap: 2,
                              marginTop: 2,
                            }}
                          >
                            <Box onClick={() => setViewSoln(true)}>
                              {" "}
                              <LogoButton
                                name={"  Answer Key & Solution"}
                                icon={"/viewSol-icon.png"}
                                style={{
                                  ...buttonStyle,
                                  "&:hover": { background: "var(--blue-new)" },
                                }}
                              />
                            </Box>
                            <Box>
                              {" "}
                              {show &&
                                show[questionIndex]?.question.questionId
                                  .isVideo === true && (
                                  <Button
                                    onClick={handleOpenModal}
                                    style={{
                                      ...buttonStyle,
                                      padding: 12,
                                    }}
                                    sx={{
                                      background:
                                        show &&
                                        show[questionIndex]?.question.questionId
                                          .isVideo === false
                                          ? "lightgrey !important"
                                          : "var(--blue-new)",
                                    }}
                                    // disabled={
                                    //   show && show[index]?.isVideo === "No"
                                    //     ? true
                                    //     : false
                                    // }
                                    startIcon={
                                      <img
                                        src="/video-solid.png"
                                        alt=""
                                        className="img-fluid"
                                        width="20px"
                                      />
                                    }
                                  >
                                    Video Solution
                                  </Button>
                                )}
                            </Box>
                          </Box>
                          {viewSol && (
                            <Box ref={bottomRef} marginTop="2em">
                              <Typography>
                                <Latex>
                                  {(show &&
                                    show[questionIndex]?.question.questionId
                                      .explanations) ||
                                    ""}
                                </Latex>
                              </Typography>
                            </Box>
                          )}
                        </Box>
                      </Box>
                    </div>
                    {/* ------Modal for video link----- */}
                    <div>
                      {" "}
                      <Modal
                        open={open}
                        onClose={handleCloseModal}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                        <Box sx={style}>
                          <iframe
                            width="100%"
                            height="100%"
                            allowfullscreen="true"
                            src={
                              show &&
                              show[questionIndex]?.question.questionId
                                .video_link
                            }
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          ></iframe>
                        </Box>
                      </Modal>
                    </div>
                    {/*================================== |Left Main end |=============================================*/}

                    {/*==================================| Right main start |========================================*/}
                    <Box
                      component="div"
                      sx={{
                        width: "100%",
                        background: "#F1F4F9",
                        boxShadow: 3,
                        textAlign: "justify",
                        height: "100%",
                        // overflow: "scroll",
                        p: 2,
                        paddingBottom: 0,
                        borderRadius: 5,
                        pb: 3,
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-evenly",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#3B36DB",
                            fontSize: 16,
                            fontFamily: "var(--font-inter)",
                            fontWeight: "900",
                          }}
                        >
                          Error Tracker
                        </Typography>
                        <Typography
                          sx={{
                            color: "#3B36DB",
                            fontSize: 16,
                            fontFamily: "var(--font-inter)",
                            fontWeight: "900",
                          }}
                        >
                          iQ GPT 1.0{" "}
                          <span>
                            <img
                              src="/viewTracker.png"
                              className="img-fluid mb-1"
                              alt=""
                            />
                          </span>
                        </Typography>
                      </Box>
                      <hr />
                      <div
                        className="d-flex flex-column justify-content-between"
                        style={{ height: "auto" }}
                      >
                        <div className="flex-item">
                          <Typography
                            sx={{
                              textAlign: "center",
                              fontSize: "17px",
                              fontWeight: 750,
                              "& > span": {
                                fontSize: 19,
                              },
                            }}
                          >
                            {(show &&
                              show?.[questionIndex]?.question.stage === 0) ||
                            show?.[questionIndex]?.question.stage === 2 ||
                            show?.[questionIndex]?.question.stage === 3
                              ? "Why did you Skip ?"
                              : (show?.[questionIndex]?.question.stage === 1 ||
                                  show?.[questionIndex]?.question.stage ===
                                    4) &&
                                show?.[questionIndex]?.question
                                  .studentAnswer ===
                                  show?.[questionIndex]?.question.questionId
                                    .answer
                              ? "Correct !! Still Confused ?"
                              : "Why did you get it wrong ?"}
                          </Typography>
                          <FormControl
                            sx={{
                              paddingTop: 1,
                              fontFamily: "var(--font-inter)",
                              fontWeight: 800,
                              textAlign: "start",
                            }}
                          >
                            <FormLabel id="demo-radio-buttons-group-label">
                              {""}
                            </FormLabel>
                            <RadioGroup
                              onChange={handleErrorForm}
                              value={errValue}
                              aria-labelledby="demo-radio-buttons-group-label"
                              name="radio-buttons-group"
                            >
                              {errorOptions &&
                                errorOptions.map((item, _) => {
                                  return (
                                    <FormControlLabel
                                      key={item.id}
                                      value={item.value}
                                      control={<Radio size="small" />}
                                      label={item.value}
                                    />
                                  );
                                })}
                            </RadioGroup>
                          </FormControl>
                        </div>

                        {/* button for redirect to error page */}
                        <div>
                          <ModifyButton
                            variant="outlined"
                            onClick={() => {
                              if (questionIndex < show.length) {
                                setQuestionIndex((prevIndex) => prevIndex + 1);
                                setViewSoln(false);
                              }
                            }}
                            endIcon={<BsChevronDoubleRight />}
                            sx={{
                              background: "#2a2b2b",
                              p: 1,
                              mb: 2,
                              width: "100%",
                              color: "white",
                              fontWeight: "bold",
                              borderRadius: "15px",
                              fontSize: "15px",
                              ":hover , :focus": {
                                background: "#2a2b2b",
                              },
                              ":disabled": {
                                background: "lightgrey !important",
                              },
                            }}
                            disabled={questionIndex === show?.length - 1}
                          >
                            Go to Next Question
                          </ModifyButton>

                          <ModifyButton
                            className="flex-item"
                            aria-describedby="mouse-over-popover"
                            onMouseEnter={handlePopoverOpen}
                            onMouseLeave={handlePopoverClose}
                            variant="outlined"
                            onClick={() =>
                              navigate(`/errortracker/${mockId}/${attemptId}`)
                            }
                            startIcon={
                              <img
                                src="/errorTracker.png"
                                className="img-fluid"
                                width={18}
                              />
                            }
                            sx={{
                              background: "#2a2b2b",
                              p: 1,
                              width: "100%",
                              color: "white",
                              fontWeight: "bold",
                              borderRadius: "15px",
                              fontSize: "15px",
                              ":hover , :focus": {
                                background: "#2a2b2b",
                              },
                            }}
                          >
                            Error Tracker Report
                          </ModifyButton>
                          <Popover
                            id="mouse-over-popover"
                            sx={{
                              pointerEvents: "none",
                            }}
                            open={popOpen}
                            anchorEl={popoverAnchorEl}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "center",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                            onClose={handlePopoverClose}
                            disableRestoreFocus
                          >
                            <Typography sx={{ p: 1 }}>
                              Complete Mock Analysis to get the Exact Mock
                              Analysis Report
                            </Typography>
                          </Popover>
                        </div>
                      </div>
                    </Box>
                    {/*==================================| Right main end |===========================================*/}
                  </Box>

                  <CustomEdgeDrawer
                    content={
                      <MiniTempCompo
                        studentAttempted={
                          show && show[questionIndex]?.studentsAttempted
                        }
                        attemptedCorrect={
                          show && show[questionIndex]?.attemptedCorrect
                        }
                        duration={
                          show && show[questionIndex]?.question?.duration
                        }
                        avgTimeSpent={
                          show && show[questionIndex]?.averageDuration
                        }
                        topperDuration={
                          show && show[questionIndex]?.durationByTopper
                        }
                      />
                    }
                    Navigation={
                      <NavigationAvatar
                        Data={show}
                        setInd={setQuestionIndex}
                        selectedQuestionIndex={questionIndex}
                        difficulty={
                          show?.[questionIndex]?.question?.questionId
                            .difficulty_level_manual
                        }
                        setViewSoln={setViewSoln}
                      />
                    }
                  />
                </>
              )}
            </Box>
          )}
        </Box>
      )}

      <ToastContainer />
    </>
  );
}


           

const NavigationAvatar = ({
  Data,
  setInd,
  selectedQuestionIndex,
  difficulty,
  setViewSoln,
}) => {
  const [visibleIndices, setVisibleIndices] = useState([]);

  const containerRef = useRef(null);

  const checkColorStatus = (data) => {
    const skipColor = "#2196F3";
    const correctColor = "#43D200";
    const incorrectColor = "#F32121";
    const partialMatchColor = "#FFA500";

    if (data.questionId.questionType === "mcq") {
      const correctAnswer = data.questionId.answerMulti;
      const studentAnswer = data.studentAnswerMulti;

      const arraysEqual = (a, b) => {
        if (a.length !== b.length) return false;
        for (let i = 0; i < a.length; i++) {
          if (!a.includes(b[i])) return false;
          break;
        }
        return true;
      };

      const hasPartialMatch = (a, b) => {
        return a.some((element) => b.includes(element));
      };

      if (!studentAnswer || studentAnswer.length === 0) {
        return skipColor;
      } else if (
        correctAnswer.length === studentAnswer.length &&
        arraysEqual(correctAnswer, studentAnswer)
      ) {
        return correctColor;
      } else if (
        correctAnswer.length > studentAnswer.length &&
        hasPartialMatch(correctAnswer, studentAnswer)
      ) {
        return partialMatchColor;
      } else {
        return incorrectColor;
      }
    } else {
      return data.stage === 0 || data.stage === 2 || data.stage === 3
        ? skipColor
        : data.studentAnswer === data.questionId.answer
        ? correctColor
        : incorrectColor;
    }
  };

  const updateVisibleIndices = () => {
    const container = containerRef.current;
    if (container) {
      const { left, right } = container.getBoundingClientRect();
      console.log("🚀 ~ updateVisibleIndices ~ left, right:", left, right);
      const newVisibleIndices = [];
      Data.forEach((_, index) => {
        const avatar = document.getElementById(`avatar-${index}`);
        console.log("🚀 ~ updateVisibleIndices ~ avatar:", avatar);
        if (avatar) {
          const { left: avatarLeft, right: avatarRight } =
            avatar.getBoundingClientRect();
          if (avatarLeft >= left && avatarRight <= right) {
            newVisibleIndices.push(index);
          }
        }
      });
      setVisibleIndices(newVisibleIndices);
    }
  };

  useEffect(() => {
    updateVisibleIndices();
    window.addEventListener("resize", updateVisibleIndices);
    return () => window.removeEventListener("resize", updateVisibleIndices);
  }, [Data]);

  console.log("🚀 ~ visibleIndices:", visibleIndices);
  return (
    <Box
      ref={containerRef}
      sx={{
        display: "flex",
        columnGap: 1,
        flexBasis: "83%",
        zIndex: 50,
        overflow: "auto",
        WebkitOverflowScrolling: "touch",
        scrollbarWidth: "none",
        msOverflowStyle: "none",
        "&::-webkit-scrollbar": {
          width: "0px",
        },
      }}
      onScroll={updateVisibleIndices}
    >
      {Data?.map((item, ind) => (
        <BootstrapTooltip
          key={ind}
          title={
            <div className="py-2">
              <div
                style={{
                  color: "black",
                  fontSize: "13px",
                  fontFamily: "var(--inter)",
                  fontWeight: 600,
                  lineHeight: "1",
                }}
              >
                Difficulty
              </div>
              <span
                style={{
                  color: "var(--orange)",
                  fontSize: "18px",
                  fontFamily: "var(--inter)",
                  fontWeight: 800,
                }}
              >
                {difficulty}
              </span>
            </div>
          }
          placement="top"
          TransitionComponent={Zoom}
          arrow
          PopperProps={{
            disablePortal: true,
            modifiers: [
              {
                name: "preventOverflow",
                options: {
                  boundary: "scrollParent",
                },
              },
            ],
          }}
          open={ind === selectedQuestionIndex && visibleIndices.includes(ind)}
        >
          <Avatar
            sx={{
              bgcolor: checkColorStatus(item.question),
              cursor: "pointer",
              width: "33.95px",
              height: "33.95px",
              fontSize: "15px",
              p: 2,
            }}
            alt="Remy Sharp"
            src="/broken-image.jpg"
            id={`avatar-${ind}`}
            onClick={() => {
              setInd(ind);
              setViewSoln(false);
            }}
          >
            <Typography variant="paragraph" sx={{ color: "white" }}>
              {ind + 1}
            </Typography>
          </Avatar>
        </BootstrapTooltip>
      ))}
    </Box>
  );
};
