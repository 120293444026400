import React, { useEffect, useRef, useState } from "react";
import MenuDrawer from "../Components/MenuDrawer";
import HeaderNew from "../Components/HeaderNew";
import { Box, Typography, Paper } from "@mui/material";
import { typographyStyles } from "../styleSheets/StyleNew";
import MultipleSelect from "../Common-comp/SelectField";
import { motion } from "framer-motion";
import LineGraph from "../Common-comp/LineGraphAcross";
import { useNavigate, useParams } from "react-router-dom";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { BsSortDown } from "react-icons/bs";
import { ToastContainer, toast } from "react-toastify";
import Table from "../Common-comp/Table";
import { fetchScoreVsPrecentile } from "../services/Analysis_api";
import ApexLineChart from "../Common-comp/ApexLineChart";
import { fetchScoreVsPrecentileByMockId } from "../services/Analysis_api";
import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import ErrorPage from "./ErrorPage";
import { useSelector } from "react-redux";
import ScoreVsPercentileMob from "./mobPages/ScoreVsPrecentileMob";

function getStyles(name, MockName, theme) {
  return {
    fontWeight:
      MockName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const ITEM_HEIGHT = 28;
const ITEM_PADDING_TOP = 3;
const MenuProps = {
  disableScrollLock: true,
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 100,
    },
  },
};

function ScoreVsPercentile() {
  const [isLoading, setLoading] = useState(true);
  const { attemptId, mockId } = useParams();
  const [data, setData] = useState([]); // set the main data from api
  const [tableData, setTableData] = useState([]);
  const [graphData, setGraphData] = useState([]);
  const [studentGraphData, setStudentGraphData] = useState([]);
  const [isErr, setIsErr] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [sectionIndex, setSectionIndex] = useState(0);
  const [tableHeading, setTableHeading] = useState([]);
  const { uid } = useSelector((state) => state.userData);
const { isMobile } = useSelector((state) => state.globalData);
  //  calling api
  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      try {
        const res = await fetchScoreVsPrecentile(mockId, attemptId, uid);
        console.log(res);
        if (res?.status === 200) {
          setData(res.data.data);
          setGraphData(res.data.data.graphData);
          setStudentGraphData(res.data.data.studentGraphData);
          setTableData(res.data.data.tableData.reverse());
          setTableHeading(res.data.data.tableKeys);
          setLoading(false);
        } else {
          //console.log("Error in fetching data: ", res);
          setLoading(false);
        }
      } catch (err) {
        //console.log(err);
        let msg = err?.response?.data?.msg;
        setLoading(false);
        // setIsErr(true);
        if (msg == undefined) {
          setErrorMsg("Some error occurred! Please reload the page.");
        } else {
          setErrorMsg(msg);
        }
        //showToastMessage(err?.response?.data?.msg);
      }
    };
    getData();
    //console.log(isWindow);
  }, []);

  return (
    <>
      <ToastContainer />
      {isErr == true ? (
        <ErrorPage errorMessage={errorMsg} />
      ) : (
        <Box component="main" sx={{ height: "100vh" }}>
          <MenuDrawer />

          <Box
            sx={{
              p: 2,
              position: "absolute",
              left: "65px",
              width: "calc(100% - 70px)",
              height: "100%",
            }}
          >
            {/* Header */}
            <Box component="header">
              <HeaderNew />
            </Box>

            {isLoading ? (
              <div
                className="d-flex align-items-center flex-column gap-2 justify-content-center"
                style={{ width: "100%", height: "80%" }}
              >
                <div class="loading-container">
                  <div class="loading"></div>
                  <div id="loading-text">Loading...</div>
                </div>
              </div>
            ) : (
              <>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="flex-item flex-fill p-2 ps-0 ">
                    <div>
                      <Typography
                        sx={{
                          ...typographyStyles.mainHeading,
                          lineHeight: 1,
                        }}
                      >
                        Score vs Percentile
                      </Typography>
                    </div>

                    <div className="d-flex gap-3 mt-3">
                      {/* {titleList && (
                        <SelectBox
                          onSelect={handleChange}
                          mockName={MockName}
                          options={titleList}
                        />
                      )} */}
                      <div>
                        <FormControl
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            columnGap: 3,
                          }}
                        >
                          <Select
                            defaultValue={0}
                            onChange={(e) => setSectionIndex(e.target.value)}
                            input={
                              <OutlinedInput
                                sx={{
                                  width: 127,
                                  borderRadius: 2,
                                  height: 32,
                                  fontSize: "12px",
                                  fontWeight: 700,
                                  fontFamily: "var(--font-inter)",

                                  ".MuiOutlinedInput-notchedOutline": {
                                    border: 1,
                                    borderColor: "#809EB9",
                                  },
                                  "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                    {
                                      border: 1,
                                      borderColor: "#809EB9",
                                    },
                                  "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                    {
                                      border: 2,
                                      borderColor: "#809EB9",
                                    },
                                }}
                              />
                            }
                            MenuProps={MenuProps}
                            inputProps={{ "aria-label": "Select value" }}
                          >
                            <MenuItem value={""} disabled>
                              <em>Select</em>
                            </MenuItem>
                            {studentGraphData?.map((item, ind) => (
                              <MenuItem
                                key={ind}
                                value={ind}
                                sx={{
                                  fontFamily: "var(--font-inter)",
                                  fontSize: "11px",
                                  fontWeight: "600",
                                }}
                              >
                                {item.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>{" "}
                    </div>
                  </div>
                  <div className="flex-item flex-fill p-2 pe-0">
                    <a href="https://www.iquanta.in/" target="_blank">
                      <img
                        src="https://iquantamedia.s3.ap-south-1.amazonaws.com/ui_images/scorePrcentile+copy.png"
                        alt="click to go mbr"
                        className="img-fluid"
                        role="button"
                        width={950}
                      />
                    </a>
                  </div>
                </div>

                <Box
                  component="main"
                  sx={{ display: "flex", width: "100%", height: "60Vh", py: 2 }}
                >
                  {/* Graph side div start */}
                  <Box
                    sx={{
                      backgroundColor: "",
                      flexBasis: "50%",
                      height: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        width: "35rem",
                        height: "100%",
                      }}
                    >
                      <motion.div
                        initial={{ opacity: 0, y: -20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 1.0 }}
                        style={{ width: "100vw", height: "100%" }}
                      >
                        {/* <LineGraph Data={graphData} xkey={"x"} ykey={"y"} /> */}
                        <ApexLineChart
                          graphData={graphData[sectionIndex]?.data}
                          studentGraphData={
                            studentGraphData[sectionIndex]?.data
                          }
                        />
                        <Box sx={{ marginLeft: 7 }}>
                          <img
                            src="/Group316(1).svg"
                            alt="img"
                            height={15}
                          ></img>
                        </Box>
                      </motion.div>
                    </Box>
                  </Box>
                  {/* Graph side div end */}

                  {/*table side box start*/}
                  <Box
                    component={Paper}
                    sx={{
                      flexBasis: "40%",
                      height: "90%",
                      boxShadow: 10,
                    }}
                  >
                    {tableData && (
                      <Table
                        data={{
                          headings: [{ name: "%ile" }, ...tableHeading],
                          body: tableData,
                        }}
                      />
                    )}
                  </Box>
                  {/*Question side box end*/}
                </Box>
              </>
            )}
          </Box>
        </Box>
      )}
    </>
  );
}


export default ScoreVsPercentile;
