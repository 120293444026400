import { useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import {
  PieChart,
  Pie,
  Legend,
  Tooltip,
  Cell,
  ResponsiveContainer,
} from "recharts";

function MyPieChart({ data, type }) {
  const colors = ["#3391FF", "#674FFF", "#FF4F6E"];
  const RADIAN = Math.PI / 180;
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        fontSize={isSmallScreen ? 10 : 15}
        fontWeight={600}
        fontFamily="var(--inter)"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  return (
    <div
      style={{
        width: isSmallScreen ? "80vw" : "35vw",
        height: isSmallScreen ? "80vw" : "auto",
        borderRadius: "15px",
        margin: "0 auto",
      }}
    >
      <ResponsiveContainer width="100%" height={isSmallScreen ? 300 : 400}>
        <PieChart>
          <defs>
            <filter id="shadow" x="-20%" y="-20%" width="140%" height="140%">
              <feOffset result="offOut" in="SourceGraphic" dx="2" dy="9" />
              <feColorMatrix
                result="matrixOut"
                in="offOut"
                type="matrix"
                values="0.8 0 0 0 0 0 0.5 0 0 0 0 0 0.5 0 0 0 0 0 1 0"
              />
              <feGaussianBlur
                result="blurOut"
                in="matrixOut"
                stdDeviation="5"
              />
              <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
            </filter>
          </defs>
          <Pie
            dataKey="graphValue"
            nameKey={"level"}
            isAnimationActive={true}
            data={data}
            cx="50%"
            cy="50%"
            cornerRadius={8}
            stroke="none"
            labelLine={false}
            label={renderCustomizedLabel}
            paddingAngle={10}
            innerRadius={isSmallScreen ? 60 : 80}
            outerRadius={isSmallScreen ? 100 : 150}
            filter="url(#shadow)"
          >
            {data?.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={colors[index % colors.length]}
              />
            ))}
          </Pie>
          <Legend layout="vertical" verticalAlign="middle" align="right" />
          <Tooltip />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
}

export default MyPieChart;
