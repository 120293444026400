import { createSlice } from "@reduxjs/toolkit";

const globalSlice = createSlice({
  name: "globalSlice",
  initialState: {
    isMobile:false
  },
  reducers: {
    setWindowQuery(state, action) {
    state.isMobile=action.payload;
    },
  
  },
});

export default globalSlice.reducer;
export const { setWindowQuery } = globalSlice.actions;
