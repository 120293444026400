import * as React from "react";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Table from "@mui/material/Table";
import { useState, useEffect } from "react";
import { RxCross2 } from "react-icons/rx";
import { FcCheckmark } from "react-icons/fc";
import { Typography, sliderClasses, useMediaQuery } from "@mui/material";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import { ModifyButton } from "../styleSheets/Style";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled, useTheme } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { FaCheck } from "react-icons/fa6";

function SectionAnalysis() {
  const [data, setData] = useState([]);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"))
  const { sectionWiseAnalysis, sections } = useSelector(
    (state) => state.analysisData
  );
  const [sectionIndex, setSectionIndex] = useState(0);
  // console.log(data);

  useEffect(() => {
    if (sectionWiseAnalysis) {
      setData(sectionWiseAnalysis[sectionIndex].questionList);
    }
  }, [sectionWiseAnalysis, sectionIndex]);
  //console.log(data);

  const headings = [
    "Serial no.",
    "Topic",
    "Subtopic",
    //"Correct / Incorrect / Partial Correct",
    "Correct / Incorrect",
    "Difficulty",
    "Your's Time",
    "Answered correct",
    "Topper's Time",
  ];
  const convertStoMs = (seconds) => {
    let minutes = Math.floor(seconds / 60);
    let extraSeconds = seconds % 60;
    minutes = minutes < 10 ? +minutes : minutes;
    extraSeconds = extraSeconds < 10 ? +extraSeconds : extraSeconds;
    //  //console.log(minutes, extraSeconds);
    return `${minutes + "." + extraSeconds + " min"}`;
  };
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#4d4d4d",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box sx={{ display: "flex", flexDirection: "row", maxWidth:"100%", overflow:"scroll", gap: isSmallScreen? "5px":"10px", p:isSmallScreen?  2 :4 }}>
        {sections?.map((e, ind) => {
          return (
            <ModifyButton
              variant="filled"
              key={ind}
              onClick={() => setSectionIndex(ind)}
              // className={`${activeButton === "VARC" ? "active" : ""}`}
              sx={{
                p: 2,
                height: "35px",
                border: "2px solid transparent",
                minWidth:isSmallScreen ?  "100px" :"200px",
                color: ind === sectionIndex ? "white" : "black",
                fontWeight: "bold",
                background: ind === sectionIndex && "var( --gray-new)",
              }}
            >
              {e.name?.toUpperCase()}
            </ModifyButton>
          );
        })}
      </Box>
      <TableContainer
        sx={{
          display: "flex",
          flexWrap: { sm: "wrap", md: "wrap", lg: "nowrap", xl: "nowrap" },
        }}
        component={Paper}
      >
        <Table sx={{ maxWidth: "auto" }} aria-label="customized table">
          <TableHead>
            <TableRow sx={{ background: "white", width: "100%" }}>
              {headings.map((heading, ind) => {
                return (
                  <StyledTableCell
                    align="left"
                    key={ind}
                    className="fw-bold"
                    sx={{ fontSize: "16px" }}
                  >
                    {heading}
                  </StyledTableCell>
                );
              })}
            </TableRow>
          </TableHead>

          <TableBody>
            {data?.map((item, ind) => {
              return (
                <StyledTableRow
                  key={ind}
                  sx={{
                    background: "white",
                    border: "none",
                    color: "black",
                    cursor: "pointer",
                  }}
                >
                  <StyledTableCell align="left">
                    <Typography fontSize="14px" fontWeight={"bold"}>
                      {ind + 1}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <Typography fontSize="14px">{item.topic}</Typography>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <Typography fontSize="14px">
                      {item.subtopic?.map((e, i) => {
                        return (i === 0 ? "" : " | ") + e.title;
                      })}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {item?.correct === "NA" ? (
                      "NA"
                    ) : item.partialCorrect === true ? (
                      <FaCheck  color="#A020F0"/>
                    ) : item.correct === true ? (
                      <FcCheckmark />
                    ) : !item?.correct && !item.partialCorrect ? (
                      <RxCross2 color="red" />
                    ) : (
                      ""
                    )}
                  </StyledTableCell>

                  <StyledTableCell align="left">
                    <Typography fontSize="14px">
                      {" "}
                      {item.difficulty?.charAt(0).toUpperCase() +
                        item.difficulty?.slice(1)}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <Typography fontSize="14px">
                      {item.duration
                        ? convertStoMs(Number(item.duration))
                        : "N/A"}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell align="left" sx={{ color: "#0C58B6" }}>
                    <Typography fontSize="14px">
                      {item.answeredCorrect === null ||
                      item.answeredCorrect === undefined
                        ? "N/A"
                        : item.answeredCorrect + " %"}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell align="left" sx={{ color: "#0C58B6" }}>
                    <Typography fontSize="14px">
                      {item.toppersTime
                        ? convertStoMs(Number(item.toppersTime))
                        : "N/A"}
                    </Typography>
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default SectionAnalysis;
