import React, { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { get_attemptId_And_Questions } from "../services/Mock_api";
import { SkewLoader } from "react-spinners";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addMockData,
  setCurrentSectionIndex,
} from "../store/slices/mockDataSlice";
import { addStudentResponse } from "../store/slices/mockDataSlice";

const UserAuth = () => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(true);
  const { state } = useLocation();
  const dispatch = useDispatch();
  const { name, email, _id } = useSelector((state) => state.userData);

  //console.log(state);
  useEffect(() => {
    //create attempt id function call
    getAttemptIdAndQuestions();
  }, []);

  // Function for creating attempt id
  const getAttemptIdAndQuestions = async () => {
    ////console.log("creating attemptid");
    try {
      const response = await get_attemptId_And_Questions(
        name,
        email,
        _id,
        state.mockId,
        state.setId
      );
      console.log(response);
      if (response?.status === 200) {
        dispatch(addMockData(response?.data?.data));
        dispatch(addStudentResponse(response?.data?.data.sections));
        dispatch(setCurrentSectionIndex(0));
        navigate("/main");
      } else if (response?.status == 201 || response?.status == 202) {
        showToastMessage(
          response?.data?.message || "Please Make a Purchase To Access!"
        );
        localStorage.clear();
        setTimeout(() => {
          window.location.href = "https://www.iquanta.in/cat-mock-test";
        }, 4000);
      } else {
        showToastMessage(response?.data?.message);
        return;
      }
    } catch (err) {
      //console.log(err);
      showToastMessage();
    }
  };
  const showToastMessage = (msg) => {
    toast.error(
      msg == undefined
        ? "Some error occurred! Please reload the page."
        : msg.toUpperCase(),
      {
        position: toast.POSITION.TOP_CENTER,
      }
    );
    return setLoader(false);
  };

  return (
    <React.Fragment>
      <ToastContainer />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100vw",
          height: "95vh",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {loader ? (
        <>
        <SkewLoader color="var(--orange)" size='85px'/>
        <h6
          className="loader_title"
          style={{ textAlign: "center", marginTop: "1em" }}
        >
          Authenticating..... Please wait!
        </h6>
      </>
        ) : (
          <div></div>
        )}
      </div>
    </React.Fragment>
  );
};
export default UserAuth;
