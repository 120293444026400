import React, { useEffect, useState, useRef, useCallback } from "react";
import MenuDrawer from "../../Components/MenuDrawer";
import HeaderNew from "../../Components/HeaderNew";
import {
  Box,
  Typography,
  Stack,
  Card,
  CardContent,
  Chip,
  Divider,
} from "@mui/material";
import { typographyStyles, style } from "../../styleSheets/StyleNew";
import MultipleSelect from "../../Common-comp/SelectField";
import { CustomizedAccordions } from "../../Common-comp/CustomizedAccordian";
import FormControl from "@mui/material/FormControl";
import { FaFilter } from "react-icons/fa";

import { LogoCard } from "../../Common-comp/Card";
import { AnalysisAcrossCard } from "../../services/DataFiles";
import { useParams } from "react-router-dom";
import {
  fetchOverallAcross,
  getMockPackageListUserAttempted,
} from "../../services/Analysis_api";
import PieGraphNew from "../../Common-comp/PieGraphNew";
import ExampleAlignmentButtons from "../../Common-comp/RadioView";
import LineGraph from "../../Common-comp/LineGraphAcross";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import ErrorPage from ".././ErrorPage";
import { getAccrossDataByFilter } from "../../services/Analysis_api";
import { SubHeading } from "../../styleSheets/styleComp";

//=========================================|| FILTER LIST OF MOCKS DATA ||  =======================//
const FilterList = ({ mocksList, handleChange, scrollTo, ...others }) => {
  console.log("🚀 ~ FilterList ~ others:", others);
  return (
    <FormControl>
      <Box
        className="scrollbar-hide "
        sx={{ display: "flex", overflow: "scroll", gap: 1 }}
      >
        <Chip
          label="All"
          onClick={() => {
            handleChange("All");
            scrollTo("info"); // Replace "divId" with the actual ID of the element to scroll to
          }}
          sx={{
            border: others?.activeTab === "All" && "1px solid  black",
          }}
          skipFocusWhenDisabled={false}
          disableFocusRipple
          clickable
          key="All"
        />
        {mocksList?.map((item, index) => (
          <Chip
            label={item.title}
            onClick={() => {
              handleChange(index);
              scrollTo("info");
            }}
            disableFocusRipple
            disableRipple
            sx={{
              border: others.activeTab === index && "1px solid  black",
            }}
            clickable
            key={index}
          />
        ))}
      </Box>
    </FormControl>
  );
};

const GraphLegend = [
  { shade: "#4CAF50", Value: "Correct" },
  { shade: " #ff0000", Value: "Incorrect" },
  { shade: "#B7B7B7", Value: "Skipped" },
];

const CardStyle = {
  fontSize: 8,
  width: "169px",
  height: "52px",
  display: "flex",
  flexDirection: "row-reverse",
  justifyContent: "flex-end",
  columnGap: 10,
  alignItems: "center",
  alignContent: "center",
};

const cardsColor = ["#FFD800", "#006CFF", "#46CB18"];

//====================================|| MAIN COMPONENT ||===========================================//
function AnalysisAcrossMob() {
  const { attemptId } = useParams();
  const [mocksList, setMocksList] = useState([]); // This state for setting the filter for left side list
  const [view, setView] = useState("Table"); // View setting state graph or table
  const { uid } = useSelector((state) => state.userData);
  const [sectionIndex, setSectionIndex] = useState(0);
  const [analysisData, setAnalysisData] = useState();
  const [tableData, setTableData] = useState([]);
  const [tableDataType, setTableDataType] = useState("acrossTopic");
  const [activeTab, setActiveTab] = useState("All");
  const [graphData, setGraphData] = useState();
  const [mainData, setMainData] = useState();
  const [analysisMetrics, setAnalysisMetrics] = useState();
  const [packageList, setPackageList] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [isErr, setIsErr] = useState(false);
  const [errorMsg, setErrorMsg] = useState();
  const [mockType, setMockType] = useState(null);

  const ITEM_HEIGHT = "48";
  const ITEM_PADDING_TOP = 3;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      },
    },
  };
  //console.log("SectionNAme", sectionName);

  const scrollableDivRef = useRef(null);
  useEffect(() => {
    setMockType(packageList[0]?._id);
  }, [packageList]);

  const handleMockCategoryChange = async (value) => {
    setMockType(value);
    console.log("value:", value);
  };

  // useEffect(() => {
  //   const fetchData = async (uid, attemptId) => {
  //     try {
  //       setLoading(true);
  //       const response = await fetchOverallAcross(uid, attemptId);
  //       //console.log("Analysis across Data", response);
  //       if (response?.status === 200) {
  //         setMainData(response?.data.data);
  //         setAnalysisData(response?.data.data.acrossAnalysisData);
  //         setTableData(
  //           response?.data.data.acrossAnalysisData[sectionIndex]?.acrossTopic
  //         );
  //         setGraphData(response?.data.data.graphData);
  //         setMocksList(response?.data.data.allMocksData); // all mock data list
  //         setAnalysisMetrics(response?.data.data.analyticsMetrics);
  //         setLoading(false);
  //       } else {
  //         showToastMessage();
  //         setLoading(false);
  //       }
  //     } catch (err) {
  //       console.log(err);
  //       showToastMessage(err?.response?.data?.error);
  //     }
  //   };
  //   fetchData(uid, attemptId);
  // }, []);

  useEffect(() => {
    const fetchPackageList = async (uid, attemptId) => {
      setLoading(true);
      let response = await getMockPackageListUserAttempted(attemptId, uid);
      if (response?.status === 200) {
        setPackageList(response.data.data.data);
        setLoading(false);
      }
    };
    fetchPackageList(uid, attemptId);
  }, []);

  useEffect(() => {
    const fetchData = async (uid, attemptId) => {
      try {
        setLoading(true);
        if (mockType) {
          const response = await getAccrossDataByFilter(
            attemptId,
            uid,
            mockType
          );
          if (response?.status === 200) {
            setMainData(response?.data.data);
            setAnalysisData(response?.data.data.acrossAnalysisData);
            setTableData(
              response?.data.data.acrossAnalysisData[sectionIndex]?.acrossTopic
            );
            setGraphData(response?.data.data.graphData);
            setMocksList(response?.data.data.allMocksData); // all mock data list
            setAnalysisMetrics(response?.data.data.analyticsMetrics);
            setLoading(false);
          } else {
            showToastMessage();
            setLoading(false);
          }
        }
      } catch (err) {
        console.log(err);
        showToastMessage(err?.response?.data?.error);
      }
    };
    fetchData(uid, attemptId);
  }, [mockType]);

  const handleFilter = (e) => {
    console.log(e);
    setActiveTab(e);

    if (e == "All") {
      setAnalysisData(mainData?.acrossAnalysisData);
    } else {
      console.log(e);
      setAnalysisData(mainData?.allMocksData[e].sections);
    }
  };

  const scrollToDiv = (id) => {
    const element = document.getElementById(id);
    element.scrollIntoView({ behavior: "smooth" });
  };

  const showToastMessage = (obj) => {
    setIsErr(true);
    setErrorMsg(obj);
  };

  const ListedData = (data, AnalysisData) => {
    console.log("Daata of listed data", data);
    return [
      {
        name: AnalysisData[0].title,
        value: data.totalQuestions,
      },
      {
        name: AnalysisData[1].title,
        value: data.attemptedQuestions,
      },
      {
        name: AnalysisData[2].title,
        value: data.correctQuestions,
      },
      {
        name: AnalysisData[3].title,
        value: data.incorrectQuestions,
      },
      {
        name: AnalysisData[4].title,
        value: data.skippedQuestions,
      },
    ];
  };

  useEffect(() => {
    if (analysisData) {
      setTableData(analysisData[sectionIndex]?.[tableDataType]);
    }
  }, [sectionIndex, tableDataType, analysisData]);

  if (isErr) {
    return <ErrorPage error={errorMsg}></ErrorPage>;
  }

  console.log(analysisData, "analysisData");
  console.log(sectionIndex);
  console.log(tableData, "tableData");
  console.log("🚀 ~ AnalysisAcrossMob ~ analysisData:", analysisData);
  console.log("🚀 ~ AnalysisAcrossMob ~ tableDataType:", tableDataType);
  return (
    <div className="p-2">
      <Box component="header">
        <HeaderNew />
      </Box>
      {isLoading ? (
        <div
          className="d-flex align-items-center flex-column gap-2 justify-content-center"
          style={{ width: "100%", height: "80%" }}
        >
          <div class="loading-container">
            <div class="loading"></div>
            <div id="loading-text">Loading...</div>
          </div>
          <Typography sx={{ fontWeight: 500 }}>
            It may take some longer, Please be patient!
          </Typography>
        </div>
      ) : (
        <>
          {/*======================================||HEADER SECTION ==================================================|| */}
          <Box
            component="div"
            className="d-flex flex-column  justify-content-start align-items-start g-2"
            sx={{ my: 2, width: "100%" }}
          >
            <Typography
              sx={{
                ...typographyStyles.mainHeading,
                pt: 2,
              }}
            >
              {" "}
              Analysis Across All Mocks
            </Typography>
            <Stack
              direction="row"
              spacing={2}
              alignItems={"center"}
              sx={{
                fontFamily: "var(--font-inter)",
                fontSize: 12,
                fontWeight: 600,
              }}
            >
              <span>Change View</span>{" "}
              <ExampleAlignmentButtons setValue={setView} value={view} />
            </Stack>
            <Box
              sx={{
                display: "flex",
                gap: 3,
                mt: 2,
                flexWrap: "wrap",
                width: "100%",
              }}
            >
              <FormControl
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  columnGap: 3,
                }}
              >
                <Select
                  defaultValue={0}
                  onChange={(e) => setSectionIndex(e.target.value)}
                  input={
                    <OutlinedInput
                      sx={{
                        width: 127,
                        borderRadius: 2,
                        height: 32,
                        fontSize: "12px",
                        fontWeight: 700,
                        fontFamily: "var(--font-inter)",

                        ".MuiOutlinedInput-notchedOutline": {
                          border: 1,
                          borderColor: "#809EB9",
                        },
                        "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                          {
                            border: 1,
                            borderColor: "#809EB9",
                          },
                        "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                          {
                            border: 2,
                            borderColor: "#809EB9",
                          },
                      }}
                    />
                  }
                  MenuProps={MenuProps}
                  inputProps={{ "aria-label": "Select value" }}
                >
                  <MenuItem value={""} disabled>
                    <em>Select</em>
                  </MenuItem>
                  {mainData?.acrossAnalysisData.map((item, ind) => (
                    <MenuItem
                      key={ind}
                      value={ind}
                      sx={{
                        fontFamily: "var(--font-inter)",
                        fontSize: "11px",
                        fontWeight: "600",
                      }}
                    >
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  columnGap: 3,
                }}
              >
                <Select
                  defaultValue={"fullMocks"}
                  onChange={(e) => handleMockCategoryChange(e.target.value)}
                  value={mockType}
                  input={
                    <OutlinedInput
                      sx={{
                        width: 127,
                        borderRadius: 2,
                        height: 32,
                        fontSize: "12px",
                        fontWeight: 700,
                        fontFamily: "var(--font-inter)",

                        ".MuiOutlinedInput-notchedOutline": {
                          border: 1,
                          borderColor: "#809EB9",
                        },
                        "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                          {
                            border: 1,
                            borderColor: "#809EB9",
                          },
                        "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                          {
                            border: 2,
                            borderColor: "#809EB9",
                          },
                      }}
                    />
                  }
                  MenuProps={MenuProps}
                  inputProps={{ "aria-label": "Select value" }}
                >
                  <MenuItem value={""} disabled>
                    <em>Select</em>
                  </MenuItem>
                  {packageList?.map((item, ind) => (
                    <MenuItem
                      key={ind}
                      value={item._id}
                      sx={{
                        fontFamily: "var(--font-inter)",
                        fontSize: "11px",
                        fontWeight: "600",
                      }}
                    >
                      {item.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>{" "}
          </Box>

          {/* -------------------------------------|| Main Section || ---------------------------------------------------*/}
          <Box
            component="main"
            sx={{
              width: "100%",
              height: "auto",
              mt: 3,
            }}
          >
            {/*-------------------------------|| TAB SECTION   ||---------------------------------------------*/}
            <Box
              sx={{
                flexBasis: "15%",
                height: "100%",
                overflow: "scroll",

                // border: "2px solid #928F8F ",
              }}
            >
              <Stack
                spacing={2}
                direction="column"
                useFlexGap
                overflow="scroll"
                mb={2}
              >
                {mocksList && (
                  <FilterList
                    scrollTo={scrollToDiv}
                    mocksList={mocksList}
                    handleChange={handleFilter}
                    activeTab={activeTab}
                  />
                )}
              </Stack>
            </Box>

            {/*---------------------------------|| GRAPH  AND TABLE SECTION || -----------------------------------------------*/}
            <Box
              className={view === "Table" ? "table-section" : "graph-section"}
              sx={{
                flexBasis: "100%",
                height: "100%",
                background: "#F6F7F8",
                p: 1,
                borderRadius: "15px",
                overflow: "scroll",
              }}
              ref={scrollableDivRef}
            >
              {/* Switching sections */}

              {view === "Table" ? (
                <>
                  <Stack
                    justifyContent={"center"}
                    my={5}
                    direction="row"
                    id="lineGraph"
                  >
                    <motion.div
                      initial={{ opacity: 0, y: -20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 1.0 }}
                      style={{ width: "80vw", height: "20em" }}
                    >
                      {graphData ? (
                        <LineGraph
                          Data={graphData[sectionIndex]?.data}
                          xKey={"title"}
                          ykey={
                            graphData[sectionIndex]?.data?.[0]?.percentile ===
                            null
                              ? "score"
                              : "percentile"
                          }
                        />
                      ) : (
                        <div className="text-center">
                          Graph Data Not Available
                        </div>
                      )}
                    </motion.div>
                  </Stack>
                  <hr />
                  {/* Cards of table*/}
                  <Box
                    component="div"
                    sx={{
                      display: "flex",
                      gap: 1,
                      justifyContent: "start",
                      alignItems: "center",
                    }}
                  >
                    Filter By <FaFilter /> :
                    <MultipleSelect
                      options={[
                        { name: "Topics", value: "acrossTopic" },
                        {
                          name: "Sub-Topics",
                          value: "acrossSubTopic",
                        },
                      ]}
                      setType={setTableDataType}
                    />
                  </Box>

                  {/* ------------------------------------------||  Table  || ------------------------------------------------------------ */}
                  <Box component="div" id="info" mt={4}>
                    {tableData.length > 0 ? (
                      tableData?.map((item) => {
                        return (
                          <div className="mt-3 mx-auto">
                            <CustomizedAccordions
                              // Sending Data through Props
                              title={item[tableDataType.slice(6).toLowerCase()]}
                              subtitle={item?.[tableDataType]}
                              icon={
                                tableDataType === "acrossTopic"
                                  ? "/CardsIcons/mainTopic.png"
                                  : "/CardsIcons/subtopic.png"
                              }
                              infoIcon={"/circle-info-solid.svg"}
                              style={{ iconSize: 30 }}
                              // backgroundColor={backgroundColor}
                              offer={ListedData(item, AnalysisAcrossCard)}
                            />
                          </div>
                        );
                      })
                    ) : (
                      <Card
                        className="text-center w-100"
                        sx={{ padding: 2, borderRadius: 3 }}
                      >
                        <h6 className="pt-2">data not available</h6>
                      </Card>
                    )}
                  </Box>

                  {/*------------------------------------------|| ANALYSIS METRIC   ||-------------------------------------------- */}
                  <Typography
                    sx={{
                      ...typographyStyles.mainHeading,
                      pt: 2,
                      fontSize: 24,
                    }}
                  >
                    {" "}
                    Analysis Metrics
                  </Typography>

                  {/* CARDS RENDER */}
                  <div className="my-2 d-flex justify-content-center flex-wrap flex-column border-top">
                    {["Weak", "Moderate", "Strong"].map((type, index) => {
                      if (type == "Weak") {
                        return (
                          <>
                            <div className="d-flex flex-column p-2" key={index}>
                              <Typography
                                sx={{
                                  ...typographyStyles.mainHeading,
                                  lineHeight: 4,
                                  fontSize: "17.02px",
                                  pl: 2,
                                }}
                              >
                                {" "}
                                <span className="me-2">
                                  <img
                                    src={
                                      type === "Weak"
                                        ? "/CardsIcons/broken.png"
                                        : type === "Moderate"
                                        ? "/CardsIcons/flag.png"
                                        : "/CardsIcons/arm.png"
                                    }
                                    width={28}
                                    className="img-fluid"
                                  />
                                </span>{" "}
                                {type}
                              </Typography>

                              {analysisMetrics?.length > 0 ? (
                                analysisMetrics[sectionIndex]?.topics?.map(
                                  (item, ind) => {
                                    if (item.accuracy < 33) {
                                      return (
                                        <motion.div
                                          key={ind}
                                          initial={{
                                            opacity: 0,
                                            scale: 0.5,
                                          }}
                                          animate={{
                                            opacity: 1,
                                            scale: 1,
                                          }}
                                          transition={{ duration: 0.5 }}
                                        >
                                          <MetricCards
                                            cardTitle={item.topic}
                                            icon={"/Acc.png"}
                                            style={{
                                              ...CardStyle,
                                              backgroundColor:
                                                "rgba(255, 0, 0, 0.3)",
                                            }}
                                          />
                                        </motion.div>
                                      );
                                    }
                                  }
                                )
                              ) : (
                                <>
                                  {" "}
                                  <LogoCard
                                    cardTitle={"No Topic"}
                                    // icon={"/Acc.png"}
                                    style={{
                                      ...CardStyle,
                                      fontSize: "15px",
                                      iconSize: 24,
                                      width: "17.1em",
                                      marginBottom: "15px",
                                      justifyContent: "flex-end",
                                      columnGap: "10px",
                                      borderRadius: "15px",
                                    }}
                                  />
                                </>
                              )}
                            </div>
                          </>
                        );
                      } else if (type == "Moderate") {
                        return (
                          <>
                            <div className="d-flex flex-column p-2" key={index}>
                              {analysisMetrics?.[sectionIndex]?.topics?.length >
                                0 && (
                                <Typography
                                  sx={{
                                    ...typographyStyles.mainHeading,
                                    lineHeight: 4,
                                    fontSize: "17.02px",
                                    pl: 2,
                                  }}
                                >
                                  {" "}
                                  <span className="me-2">
                                    <img
                                      src={
                                        type === "Weak"
                                          ? "/CardsIcons/broken.png"
                                          : type === "Moderate"
                                          ? "/CardsIcons/flag.png"
                                          : "/CardsIcons/arm.png"
                                      }
                                      width={28}
                                      className="img-fluid"
                                    />
                                  </span>{" "}
                                  {type}
                                </Typography>
                              )}

                              {analysisMetrics?.[sectionIndex]?.topics?.length >
                              0 ? (
                                analysisMetrics[sectionIndex]?.topics?.map(
                                  (item, ind) => {
                                    if (
                                      item.accuracy > 33 &&
                                      item.accuracy <= 66
                                    ) {
                                      return (
                                        <motion.div
                                          key={ind}
                                          initial={{
                                            opacity: 0,
                                            scale: 0.5,
                                          }}
                                          animate={{
                                            opacity: 1,
                                            scale: 1,
                                          }}
                                          transition={{ duration: 0.5 }}
                                        >
                                          <MetricCards
                                            cardTitle={item.topic}
                                            icon={"/Acc.png"}
                                            style={{
                                              ...CardStyle,
                                              backgroundColor:
                                                "rgba(0, 0, 255, 0.3)",
                                            }}
                                          />
                                        </motion.div>
                                      );
                                    }
                                  }
                                )
                              ) : (
                                <>
                                  {" "}
                                  <LogoCard
                                    cardTitle={"No Topic"}
                                    // icon={"/Acc.png"}
                                    style={{
                                      ...CardStyle,
                                      fontSize: "15px",
                                      iconSize: 24,
                                      width: "17.1em",
                                      marginBottom: "15px",
                                      justifyContent: "flex-end",
                                      columnGap: "10px",
                                      borderRadius: "15px",
                                    }}
                                  />
                                </>
                              )}
                            </div>
                          </>
                        );
                      } else {
                        return (
                          <>
                            <div className="d-flex flex-column p-2" key={index}>
                              {analysisMetrics?.[sectionIndex]?.topics?.length >
                                0 && (
                                <Typography
                                  sx={{
                                    ...typographyStyles.mainHeading,
                                    lineHeight: 4,
                                    fontSize: "17.02px",
                                    pl: 2,
                                  }}
                                >
                                  {" "}
                                  <span className="me-2">
                                    <img
                                      src={
                                        type === "Weak"
                                          ? "/CardsIcons/broken.png"
                                          : type === "Moderate"
                                          ? "/CardsIcons/flag.png"
                                          : "/CardsIcons/arm.png"
                                      }
                                      width={28}
                                      className="img-fluid"
                                    />
                                  </span>{" "}
                                  {type}
                                </Typography>
                              )}

                              {analysisMetrics?.[sectionIndex]?.topics?.length >
                              0 ? (
                                analysisMetrics[sectionIndex]?.topics?.map(
                                  (item, ind) => {
                                    if (item.accuracy > 66) {
                                      return (
                                        <motion.div
                                          key={ind}
                                          initial={{
                                            opacity: 0,
                                            scale: 0.5,
                                          }}
                                          animate={{
                                            opacity: 1,
                                            scale: 1,
                                          }}
                                          transition={{ duration: 0.5 }}
                                        >
                                          <MetricCards
                                            cardTitle={item.topic}
                                            icon={"/Acc.png"}
                                            style={{
                                              ...CardStyle,

                                              backgroundColor:
                                                "rgba(0, 255, 0, 0.3)",
                                            }}
                                          />
                                        </motion.div>
                                      );
                                    }
                                  }
                                )
                              ) : (
                                <>
                                  {" "}
                                  <LogoCard
                                    cardTitle={"No Topic"}
                                    // icon={"/Acc.png"}
                                    style={{
                                      ...CardStyle,
                                      fontSize: "15px",
                                      iconSize: 24,
                                      width: "17.1em",
                                      marginBottom: "15px",
                                      justifyContent: "flex-end",
                                      columnGap: "10px",
                                      borderRadius: "15px",
                                    }}
                                  />
                                </>
                              )}
                            </div>
                          </>
                        );
                      }
                    })}
                  </div>
                </>
              ) : (
                <>
                  <motion.div
                    initial={{ opacity: 0, y: -20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 1.0 }}
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: 1,
                      rowGap: 5,
                      justifyContent: "space-around",
                    }}
                  >
                    {tableData?.map((item, ind) => {
                      //console.log(show);
                      return (
                        <Box key={ind}>
                          <Typography
                            lineHeight={0}
                            sx={{
                              ...typographyStyles.subHeading,
                              textAlign: "center",
                            }}
                            color="black"
                          >
                            {item[tableDataType.slice(6).toLowerCase()]}
                          </Typography>
                          <Box sx={{ width: "14em", height: "15em" }}>
                            <PieGraphNew
                              data={{
                                topic:
                                  item[tableDataType.slice(6).toLowerCase()],

                                correct: item.correctQuestions,
                                incorrect: item.incorrectQuestions,
                                skipped: item.skippedQuestions,
                              }}
                              color={GraphLegend.map((e) => e.shade)}
                            />
                          </Box>
                        </Box>
                      );
                    })}
                  </motion.div>
                </>
              )}

              {view === "Graph" && (
                <Stack
                  direction="row"
                  spacing={2}
                  justifyContent={"center"}
                  mt={2}
                >
                  {GraphLegend?.map((e, ind) => {
                    return (
                      <div
                        className="d-flex gap-1 align-items-center"
                        key={ind}
                      >
                        <div
                          style={{
                            borderRadius: "40%",
                            height: "24px",
                            width: "24px",
                            background: e.shade,
                          }}
                        ></div>
                        <span
                          style={{
                            fontSize: 13,
                            fontFamily: "var(--font-inter)",
                            fontWeight: 600,
                          }}
                        >
                          {e.Value}
                        </span>
                      </div>
                    );
                  })}
                </Stack>
              )}
            </Box>
          </Box>
        </>
      )}
    </div>
  );
}

const MetricCards = ({ cardTitle, icon, style, infoIcon, select }) => {
  return (
    <div
      className="card  p-2"
      style={{
        ...style,
        border: "1px solid white",
        flexWrap: "",
        fontSize: "13px",
        iconSize: 20,
        width: "100%",
        marginBottom: "3px",
        justifyContent: "flex-end",

        borderRadius: "15px",
        alignItems: "center",
      }}
    >
      {infoIcon && (
        <div>
          <img
            src={infoIcon}
            alt=""
            className="img-fluid"
            width={style.infoIcon ? style.infoIcon : 22}
          />
        </div>
      )}

      <p className="mt-3">{cardTitle}</p>

      <div>
        <img
          src={icon}
          alt=""
          className="img-fluid"
          width={style.iconSize ? style.iconSize : 22}
        />
      </div>
    </div>
  );
};

export default AnalysisAcrossMob;
