import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/swiper-bundle.min.css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "../styleSheets/swiper.css";

// import required modules
import { FreeMode, Pagination, Mousewheel } from "swiper";
import ImgMediaCard from "./MarketPlaceCard";
import { useSelector } from "react-redux";

export default function SliderSwiper({ data }) {
  const { isMobile } = useSelector((state) => state.globalData);

  return (
    <>
      <Swiper
        slidesPerView={isMobile ? 2 : 4.5}
        spaceBetween={0}
        freeMode={true}
        pagination={{
          clickable: true,
        }}
        mousewheel={{ forceToAxis: true, enabled: true }}
        modules={[FreeMode, Pagination, Mousewheel]}
        className="mySwiper"
      >
        {data?.map((item, index) => (
          <SwiperSlide key={index}>
            
            <ImgMediaCard cardData={item} />
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
}
