import * as React from "react";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import { ModifyButton } from "../styleSheets/Style";
import Paper from "@mui/material/Paper";
import { tableCellClasses } from "@mui/material/TableCell";
import { styled, useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";

function SubtopicAnalysis() {
  const [data, setData] = useState([]);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"))
  const navigate = useNavigate();
  const { subTopicWiseAnalysis, sections } = useSelector(
    (state) => state.analysisData
  );
  const [sectionIndex, setSectionIndex] = useState(0);

  function getStyles(MockId, mockid, theme) {
    return {
      fontWeight:
        MockId === mockid
          ? theme.typography.fontWeightMedium
          : theme.typography.fontWeightRegular,
      backgroundColor:
        MockId === mockid ? theme.palette.primary.main : "inherit",
      color: MockId === mockid ? theme.palette.primary.contrastText : "inherit",
    };
  }

  useEffect(() => {
    if (subTopicWiseAnalysis) {
      setData(subTopicWiseAnalysis[sectionIndex].subTopicList);
    }
  }, [subTopicWiseAnalysis, sectionIndex]);

  // //console.log("manthan tyagi")
  //console.log(topper_data);

  const headings = [
    "Number",
    "Subtopic",
    "Total Questions",
    "Attempted Questions",
    "Correct Attempts",
    "Incorrect Attempts",
    //"Partial Correct",
    "Mark obtained by correct questions",
    "Overall score in the Subtopic ",
    "Mark obtained by Topper",
    "Mark lose by Incorrect Attempt",
  ];
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#4d4d4d",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
  <Box sx={{ display: "flex", flexDirection: "row", maxWidth:"100%", overflow:"scroll", gap: isSmallScreen? "5px":"10px", p:isSmallScreen?  2 :4 }}>
        {sections?.map((e, ind) => {
          return (
            <ModifyButton
              variant="filled"
              key={ind}
              onClick={() => setSectionIndex(ind)} // className={`${activeButton === "VARC" ? "active" : ""}`}
              sx={{
                p: 2,
                height: "35px",
                border: "2px solid transparent",
                minWidth:isSmallScreen ?  "100px" :"200px",
                color: ind === sectionIndex ? "white" : "black",
                fontWeight: "bold",
                background: ind === sectionIndex && "var( --gray-new)",
              }}
            >
              {e.name?.toUpperCase()}
            </ModifyButton>
          );
        })}
      </Box>
      <TableContainer
        sx={{
          display: "flex",
          flexWrap: { sm: "wrap", md: "wrap", lg: "nowrap", xl: "nowrap" },
        }}
        component={Paper}
      >
        <Table sx={{ maxWidth: "auto" }} aria-label="customized table">
          <TableHead>
            <TableRow sx={{ background: "white", width: "100%" }}>
              {headings.map((heading, ind) => {
                return (
                  <StyledTableCell
                    align="left"
                    key={ind}
                    className="fw-bold"
                    sx={{ fontSize: "16px" }}
                  >
                    {heading}
                  </StyledTableCell>
                );
              })}
            </TableRow>
          </TableHead>

          <TableBody>
            {data?.map((item, index) => {
              return (
                <StyledTableRow
                  key={index}
                  sx={{
                    background: "white",
                    border: "none",
                    color: "black",
                    cursor: "pointer",
                  }}
                >
                  <StyledTableCell align="left">{index + 1}</StyledTableCell>
                  <StyledTableCell align="left">
                    {item.subtopic}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {item.totalQuestions}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {item.attemptedQuestions}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {item.correctQuestions}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {item.incorrectQuestions}
                  </StyledTableCell>
                  {/* <StyledTableCell align="left">
                    {item.partialCorrectQuestions}
                  </StyledTableCell> */}
                  <StyledTableCell align="left">
                    {item.markGained}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {item.overallScore}
                  </StyledTableCell>
                  <StyledTableCell align="left" sx={{ color: "#0C58B6" }}>
                    {item.marksObtainedByTopper}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {item.marksDeducted}
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default SubtopicAnalysis;
