import React, { useEffect, useState, useRef, useCallback } from "react";
import MenuDrawer from "../Components/MenuDrawer";
import HeaderNew from "../Components/HeaderNew";
import { Box, Typography, Stack, Card, CardContent } from "@mui/material";
import { typographyStyles, style } from "../styleSheets/StyleNew";
import MultipleSelect from "../Common-comp/SelectField";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { LogoCard } from "../Common-comp/Card";
import { AnalysisAcrossCard } from "../services/DataFiles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useParams } from "react-router-dom";
import { fetchOverallAcross,getMockPackageListUserAttempted } from "../services/Analysis_api";
import PieGraphNew from "../Common-comp/PieGraphNew";
import ExampleAlignmentButtons from "../Common-comp/RadioView";
import LineGraph from "../Common-comp/LineGraphAcross";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import { TrendingUpTwoTone } from "@mui/icons-material";
import ErrorPage from "./ErrorPage";
import { getAccrossDataByFilter } from "../services/Analysis_api";
import AnalysisAcrossMob from "./mobPages/AnalysisAcrossMob";

//=========================================|| FILTER LIST OF MOCKS DATA ||  =======================//
const FilterList = ({ mocksList, handleChange, scrollTo }) => {
  return (
    <FormControl>
      <FormLabel id="demo-radio-buttons-group-label">
        {" "}
        <Typography
          variant="paragrapgh"
          sx={{ ...style.subHeading, fontSize: "21.96px", color: "black" }}
        >
          Filter
        </Typography>
      </FormLabel>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        name="radio-buttons-group"
        defaultValue={"All"}
        onClick={() => {
          scrollTo("info"); // Replace "divId" with the actual ID of the element to scroll to
        }}
      >
        <FormControlLabel
          onClick={() => handleChange("All")}
          value={"All"}
          control={<Radio />}
          label={"All"}
          key={"All"}
        />
        {mocksList?.map((item, index) => {
          return (
            <FormControlLabel
              onClick={() => handleChange(index)}
              value={index}
              control={<Radio />}
              label={item.title}
              key={index}
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
};

const GraphLegend = [
  { shade: "#4CAF50", Value: "Correct" },
  { shade: " #ff0000", Value: "Incorrect" },
  { shade: "#B7B7B7", Value: "Skipped" },
];

//==========================================| DATA TABLE |============================================//
const DataTable = ({ data, tableType }) => {
  console.log(data);
  return (
    <TableContainer
      sx={{ p: 2, borderRadius: 4, border: "none", boxShadow: 2 }}
      component={Paper}
    >
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableBody>
          {data?.map((row, ind) => (
            <TableRow
              key={ind}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell sx={{ fontWeight: "bold", width: "20%" }}>
                {row[tableType.slice(6).toLowerCase()]}
              </TableCell>
              <TableCell align="left">{row.totalQuestions}</TableCell>
              <TableCell align="left">{row.attemptedQuestions}</TableCell>
              <TableCell align="left" sx={{ color: "green" }}>
                {row.correctQuestions}
              </TableCell>
              <TableCell align="left" sx={{ color: "red" }}>
                {row.incorrectQuestions}
              </TableCell>
              <TableCell align="left">{row.skippedQuestions}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const CardStyle = {
  fontSize: 8,
  width: "169px",
  height: "52px",
  display: "flex",
  flexDirection: "row-reverse",
  justifyContent: "flex-end",
  columnGap: 10,
  alignItems: "center",
  alignContent: "center",
};


const cardsColor = ["#FFD800", "#006CFF", "#46CB18"];
//====================================|| MAIN COMPONENT ||===========================================//
function AnalysisAcross() {
  const { attemptId } = useParams();
  const [mocksList, setMocksList] = useState([]); // This state for setting the filter for left side list
  const [view, setView] = useState("Table"); // View setting state graph or table
  const { uid } = useSelector((state) => state.userData);
  const [sectionIndex, setSectionIndex] = useState(0);
  const [analysisData, setAnalysisData] = useState();
  const [tableData, setTableData] = useState([]);
  const [tableDataType, setTableDataType] = useState("acrossTopic");
  const [graphData, setGraphData] = useState();
  const [mainData, setMainData] = useState();
  const [analysisMetrics, setAnalysisMetrics] = useState();
  const [isLoading, setLoading] = useState(true);
  const [isErr, setIsErr] = useState(false);
  const [errorMsg, setErrorMsg] = useState();
  const [packageList,setPackageList]=useState([]);
  const [mockType, setMockType] = useState(null);
  const { isMobile } = useSelector((state) => state.globalData);

  const ITEM_HEIGHT = "48";
  const ITEM_PADDING_TOP = 3;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      },
    },
  };
  //console.log("SectionNAme", sectionName);

  const scrollableDivRef = useRef(null);
useEffect(()=>{
setMockType(packageList[0]?._id);
},[packageList])



  useEffect(() => {
    const fetchData = async (uid, attemptId) => {
      try {
        setLoading(true);
        if(mockType)
        {const response =  await getAccrossDataByFilter(attemptId,uid,mockType);
       if (response?.status === 200) {
          setMainData(response?.data.data);
          setAnalysisData(response?.data.data.acrossAnalysisData);
          setTableData(
            response?.data.data.acrossAnalysisData[sectionIndex]?.acrossTopic
          );
          setGraphData(response?.data.data.graphData);
          setMocksList(response?.data.data.allMocksData); // all mock data list
          setAnalysisMetrics(response?.data.data.analyticsMetrics);
          setLoading(false);
        } else {
          showToastMessage();
          setLoading(false);
        }}
      } catch (err) {
        console.log(err);
        showToastMessage(err?.response?.data?.error);
      }
    };
    fetchData(uid, attemptId);
  }, [mockType]);

  useEffect(() => {
    if (analysisData) {
      setTableData(analysisData[sectionIndex]?.[tableDataType]);
    }
  }, [sectionIndex, tableDataType, analysisData]);
  
  useEffect(()=>{
   const fetchPackageList=async(uid,attemptId)=>{
   let response =  await getMockPackageListUserAttempted(attemptId,uid);
  if (response?.status === 200) {
   setPackageList(response.data.data.data);
  }
   }
   fetchPackageList(uid,attemptId);
  },[]);
  
  const handleFilter = (e) => {
    console.log("210:",e);
    if (e == "All") {
      setAnalysisData(mainData?.acrossAnalysisData);
    } else {
      console.log("214",e);
      setAnalysisData(mainData?.allMocksData[e].sections);
    }
  };

  const scrollToDiv = (id) => {
    const element = document.getElementById(id);
    element.scrollIntoView({ behavior: "smooth" });
  };
  const showToastMessage = (obj) => {
    setIsErr(true);
    setErrorMsg(obj);
  };

 const handleMockCategoryChange=async(value)=>{
  setMockType(value);
  console.log("value:",value);
  }
  if (isErr) {
    return <ErrorPage error={errorMsg}></ErrorPage>;
  }
  // console.log(analysisData, "analysisData");
  // console.log(sectionIndex);
  // console.log(tableData, "tableData");
  // console.log(tableDataType, "tableDataType");
  // console.log(mainData, "mainData");
  // console.log(analysisMetrics?.[sectionIndex]?.topics);

  return (
    <>
      <Box component="main">
        <MenuDrawer />

        <Box
          sx={{
            position: "absolute",
            left: "65px",
            height: "100vh",
            overflow:"scroll",
            width:"calc(100% - 65px)",
            p: 2,
          }}
        >
          {/* Header */}
          <Box component="header">
            <HeaderNew />
          </Box>

          {isLoading ? (
            <div
              className="d-flex align-items-center flex-column gap-2 justify-content-center"
              style={{ width: "100%", height: "80%" }}
            >
              <div class="loading-container">
                <div class="loading"></div>
                <div id="loading-text">Loading...</div>
              </div>
              <Typography sx={{ fontWeight: 500 }}>
                It may take some longer, Please be patient!
              </Typography>
            </div>
          ) :(
            <>
              {/*======================================||HEADER SECTION ==================================================|| */}
              <Box component="div" sx={{ mt: 4 }}>
                <Box sx={{ display: "flex", gap: 3 }}>
                  <FormControl
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      columnGap: 3,
                    }}
                  >
                    <Select
                      defaultValue={0}
                      onChange={(e) => setSectionIndex(e.target.value)}
                      input={
                        <OutlinedInput
                          sx={{
                            width: 127,
                            borderRadius: 2,
                            height: 32,
                            fontSize: "12px",
                            fontWeight: 700,
                            fontFamily: "var(--font-inter)",

                            ".MuiOutlinedInput-notchedOutline": {
                              border: 1,
                              borderColor: "#809EB9",
                            },
                            "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                              {
                                border: 1,
                                borderColor: "#809EB9",
                              },
                            "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                              {
                                border: 2,
                                borderColor: "#809EB9",
                              },
                          }}
                        />
                      }
                      MenuProps={MenuProps}
                      inputProps={{ "aria-label": "Select value" }}
                    >
                      <MenuItem value={""} disabled>
                        <em>Select</em>
                      </MenuItem>
                      {mainData?.acrossAnalysisData.map((item, ind) => (
                        <MenuItem
                          key={ind}
                          value={ind}
                          sx={{
                            fontFamily: "var(--font-inter)",
                            fontSize: "11px",
                            fontWeight: "600",
                          }}
                        >
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      columnGap: 3,
                    }}
                  >
                    <Select
                      defaultValue={"fullMocks"}
                      onChange={(e) => handleMockCategoryChange(e.target.value)}
                      value={mockType}
                      input={
                        <OutlinedInput
                          sx={{
                            width: 127,
                            borderRadius: 2,
                            height: 32,
                            fontSize: "12px",
                            fontWeight: 700,
                            fontFamily: "var(--font-inter)",

                            ".MuiOutlinedInput-notchedOutline": {
                              border: 1,
                              borderColor: "#809EB9",
                            },
                            "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                              {
                                border: 1,
                                borderColor: "#809EB9",
                              },
                            "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                              {
                                border: 2,
                                borderColor: "#809EB9",
                              },
                          }}
                        />
                      }
                      MenuProps={MenuProps}
                      inputProps={{ "aria-label": "Select value" }}
                    >
                      <MenuItem value={""} disabled>
                        <em>Select</em>
                      </MenuItem>
                      {packageList.map((item, ind) => (
                        <MenuItem
                          key={ind}
                          value={item._id}
                          sx={{
                            fontFamily: "var(--font-inter)",
                            fontSize: "11px",
                            fontWeight: "600",
                          }}
                        >
                          {item.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>{" "}
                <div className="d-flex justify-content-between align-items-center">
                  <Typography
                    sx={{
                      ...typographyStyles.mainHeading,
                      pt: 2,
                    }}
                  >
                    {" "}
                    Analysis Across All Mocks
                  </Typography>

                  <Stack
                    direction="row"
                    spacing={2}
                    alignItems={"center"}
                    sx={{
                      fontFamily: "var(--font-inter)",
                      fontSize: 12,
                      fontWeight: 600,
                    }}
                  >
                    <span>Change View</span>{" "}
                    <ExampleAlignmentButtons setValue={setView} value={view} />
                  </Stack>
                </div>
              </Box>

              {/* -------------------------------------|| Main Section || ---------------------------------------------------*/}
              <Box
                component="main"
                sx={{
                  display: "flex",
                  width: "calc(100vw - 108px)",
                  height: "70vh",
                  mt: 3,
                  flexWrap: "wrap",
                }}
              >
                {/*-------------------------------|| left side Filter div start  ||---------------------------------------------*/}
                <Box
                  sx={{
                    flexBasis: "15%",
                    height: "100%",
                    overflow: "scroll",
                    py: 2,
                    // border: "2px solid #928F8F ",
                  }}
                >
                  <Stack
                    spacing={2}
                    direction="column"
                    useFlexGap
                    flexWrap="wrap"
                  >
                    {mocksList && (
                      <FilterList
                        scrollTo={scrollToDiv}
                        mocksList={mocksList}
                        handleChange={handleFilter}
                      />
                    )}
                  </Stack>
                </Box> 

 
                {/*---------------------------------|| Question side box start || -----------------------------------------------*/}
                <Box
                  className={
                    view === "Table" ? "table-section" : "graph-section"
                  }
                  sx={{
                    flexBasis: { xs: "100%", md: "85%" },
                    height: "100%",
                    background: "#F6F7F8",
                    p: 3,
                    borderRadius: "15px",
                    overflow: "scroll",
                  }}
                  ref={scrollableDivRef}
                >
                  {/* Switching sections */}

                  {view === "Table" ? (
                    <>
                      <Stack
                        justifyContent={"center"}
                        my={5}
                        direction="row"
                        id="lineGraph"
                      >
                        <motion.div
                          initial={{ opacity: 0, y: -20 }}
                          animate={{ opacity: 1, y: 0 }}
                          transition={{ duration: 1.0 }}
                          style={{ width: "80vw", height: "20em" }}
                        >
                          {graphData ? (
                            <LineGraph
                              Data={graphData[sectionIndex]?.data}
                              xKey={"title"}
                              ykey={
                                graphData[sectionIndex]?.data?.[0]
                                  ?.percentile === null
                                  ? "score"
                                  : "percentile"
                              }
                            />
                          ) : (
                            <div className="text-center">
                              Graph Data Not Available
                            </div>
                          )}
                        </motion.div>
                      </Stack>
                      <hr />
                      {/* Cards of table*/}
                      <Box
                        component="div"
                        sx={{
                          display: "flex",
                          gap: 2,
                          justifyContent: "space-around",
                          alignItems: "center",
                        }}
                      >
                        <MultipleSelect
                          options={[
                            { name: "Topics", value: "acrossTopic" },
                            {
                              name: "Sub-Topics",
                              value: "acrossSubTopic",
                            },
                          ]}
                          setType={setTableDataType}
                        />
                        {AnalysisAcrossCard.map((item, _) => (
                          <LogoCard
                            cardTitle={item.title}
                            key={item.id}
                            icon={item.icon}
                            style={CardStyle}
                          />
                        ))}
                      </Box>

 {/* ------------------------------------------||  Table  || ------------------------------------------------------------ */}
                      <Box component="div" id="info" mt={4}>
                        <Box component="div" id="info" mt={4}>
                          {tableData ? (
                            <DataTable
                              data={tableData}
                              tableType={tableDataType}
                            />
                          ) : (
                            <Card
                              className="text-center w-100"
                              sx={{ padding: 2, borderRadius: 3 }}
                            >
                              <h6 className="pt-2">data not available</h6>
                            </Card>
                          )}
                        </Box>
                      </Box>

 {/*------------------------------------------|| Cards of weak , moderate and  strong ||-------------------------------------------- */}
                      <Typography
                        sx={{
                          ...typographyStyles.mainHeading,
                          pt: 2,
                        }}
                      >
                        {" "}
                        Analysis Metrics
                      </Typography>
                      <div className="my-2 d-flex justify-content-between">
                        {["Weak", "Moderate", "Strong"].map((type, index) => {
                          if (type == "Weak") {
                            return (
                              <>
                                <div
                                  className="d-flex flex-column p-2"
                                  key={index}
                                >
                                  <Typography
                                    sx={{
                                      ...typographyStyles.mainHeading,
                                      lineHeight: 4,
                                      fontSize: "17.02px",
                                      pl: 2,
                                    }}
                                  >
                                    {" "}
                                    <span className="me-2">
                                      <img
                                        src={
                                          type === "Weak"
                                            ? "/CardsIcons/broken.png"
                                            : type === "Moderate"
                                            ? "/CardsIcons/flag.png"
                                            : "/CardsIcons/arm.png"
                                        }
                                        width={28}
                                        className="img-fluid"
                                      />
                                    </span>{" "}
                                    {type}
                                  </Typography>
                                  <Card
                                    key={type}
                                    sx={{
                                      minHeight: "19.188em",
                                      minWidth: "17.938em",
                                      backgroundColor:
                                        cardsColor[index % cardsColor.length],
                                      borderRadius: 5,
                                      display: "flex",
                                      flexDirection: "column",
                                      gap: 3,
                                    }}
                                  >
                                    <CardContent>
                                      {analysisMetrics?.length > 0 ? (
                                        analysisMetrics[
                                          sectionIndex
                                        ]?.topics?.map((item, ind) => {
                                          if (item.accuracy < 33) {
                                            return (
                                              <motion.div
                                                key={ind}
                                                initial={{
                                                  opacity: 0,
                                                  scale: 0.5,
                                                }}
                                                animate={{
                                                  opacity: 1,
                                                  scale: 1,
                                                }}
                                                transition={{ duration: 0.5 }}
                                              >
                                                <LogoCard
                                                  cardTitle={item.topic}
                                                  icon={"/Acc.png"}
                                                  style={{
                                                    ...CardStyle,
                                                    fontSize: "15px",
                                                    iconSize: 24,
                                                    width: "17.1em",
                                                    marginBottom: "15px",
                                                    justifyContent: "flex-end",
                                                    columnGap: "10px",
                                                    borderRadius: "15px",
                                                  }}
                                                />
                                              </motion.div>
                                            );
                                          }
                                        })
                                      ) : (
                                        <>
                                          {" "}
                                          <LogoCard
                                            cardTitle={"No Topic"}
                                            // icon={"/Acc.png"}
                                            style={{
                                              ...CardStyle,
                                              fontSize: "15px",
                                              iconSize: 24,
                                              width: "17.1em",
                                              marginBottom: "15px",
                                              justifyContent: "flex-end",
                                              columnGap: "10px",
                                              borderRadius: "15px",
                                            }}
                                          />
                                        </>
                                      )}
                                    </CardContent>
                                  </Card>
                                </div>
                              </>
                            );
                          } else if (type == "Moderate") {
                            return (
                              <>
                                <div
                                  className="d-flex flex-column p-2"
                                  key={index}
                                >
                                  <Typography
                                    sx={{
                                      ...typographyStyles.mainHeading,
                                      lineHeight: 4,
                                      fontSize: "17.02px",
                                      pl: 2,
                                    }}
                                  >
                                    {" "}
                                    <span className="me-2">
                                      <img
                                        src={
                                          type === "Weak"
                                            ? "/CardsIcons/broken.png"
                                            : type === "Moderate"
                                            ? "/CardsIcons/flag.png"
                                            : "/CardsIcons/arm.png"
                                        }
                                        width={28}
                                        className="img-fluid"
                                      />
                                    </span>{" "}
                                    {type}
                                  </Typography>
                                  <Card
                                    key={type}
                                    sx={{
                                      minHeight: "19.188em",
                                      minWidth: "17.938em",
                                      backgroundColor:
                                        cardsColor[index % cardsColor.length],
                                      borderRadius: 5,
                                      display: "flex",
                                      flexDirection: "column",
                                      gap: 3,
                                    }}
                                  >
                                    <CardContent>
                                      {analysisMetrics?.[sectionIndex]?.topics
                                        ?.length > 0 ? (
                                        analysisMetrics[
                                          sectionIndex
                                        ]?.topics?.map((item, ind) => {
                                          if (
                                            item.accuracy > 33 &&
                                            item.accuracy <= 66
                                          ) {
                                            return (
                                              <motion.div
                                                key={ind}
                                                initial={{
                                                  opacity: 0,
                                                  scale: 0.5,
                                                }}
                                                animate={{
                                                  opacity: 1,
                                                  scale: 1,
                                                }}
                                                transition={{ duration: 0.5 }}
                                              >
                                                <LogoCard
                                                  cardTitle={item.topic}
                                                  icon={"/Acc.png"}
                                                  style={{
                                                    ...CardStyle,
                                                    fontSize: "15px",
                                                    iconSize: 24,
                                                    width: "17.1em",
                                                    marginBottom: "15px",
                                                    justifyContent: "flex-end",
                                                    columnGap: "10px",
                                                    borderRadius: "15px",
                                                  }}
                                                />
                                              </motion.div>
                                            );
                                          }
                                        })
                                      ) : (
                                        <>
                                          {" "}
                                          <LogoCard
                                            cardTitle={"No Topic"}
                                            // icon={"/Acc.png"}
                                            style={{
                                              ...CardStyle,
                                              fontSize: "15px",
                                              iconSize: 24,
                                              width: "17.1em",
                                              marginBottom: "15px",
                                              justifyContent: "flex-end",
                                              columnGap: "10px",
                                              borderRadius: "15px",
                                            }}
                                          />
                                        </>
                                      )}
                                    </CardContent>
                                  </Card>
                                </div>
                              </>
                            );
                          } else {
                            return (
                              <>
                                <div
                                  className="d-flex flex-column p-2"
                                  key={index}
                                >
                                  <Typography
                                    sx={{
                                      ...typographyStyles.mainHeading,
                                      lineHeight: 4,
                                      fontSize: "17.02px",
                                      pl: 2,
                                    }}
                                  >
                                    {" "}
                                    <span className="me-2">
                                      <img
                                        src={
                                          type === "Weak"
                                            ? "/CardsIcons/broken.png"
                                            : type === "Moderate"
                                            ? "/CardsIcons/flag.png"
                                            : "/CardsIcons/arm.png"
                                        }
                                        width={28}
                                        className="img-fluid"
                                      />
                                    </span>{" "}
                                    {type}
                                  </Typography>
                                  <Card
                                    key={type}
                                    sx={{
                                      minHeight: "19.188em",
                                      minWidth: "17.938em",
                                      backgroundColor:
                                        cardsColor[index % cardsColor.length],
                                      borderRadius: 5,
                                      display: "flex",
                                      flexDirection: "column",
                                      gap: 3,
                                    }}
                                  >
                                    <CardContent>
                                      {analysisMetrics?.[sectionIndex]?.topics
                                        ?.length > 0 ? (
                                        analysisMetrics[
                                          sectionIndex
                                        ]?.topics?.map((item, ind) => {
                                          if (item.accuracy > 66) {
                                            return (
                                              <motion.div
                                                key={ind}
                                                initial={{
                                                  opacity: 0,
                                                  scale: 0.5,
                                                }}
                                                animate={{
                                                  opacity: 1,
                                                  scale: 1,
                                                }}
                                                transition={{ duration: 0.5 }}
                                              >
                                                <LogoCard
                                                  cardTitle={item.topic}
                                                  icon={"/Acc.png"}
                                                  style={{
                                                    ...CardStyle,
                                                    fontSize: "15px",
                                                    iconSize: 24,
                                                    width: "17.1em",
                                                    marginBottom: "15px",
                                                    justifyContent: "flex-end",
                                                    columnGap: "10px",
                                                    borderRadius: "15px",
                                                  }}
                                                />
                                              </motion.div>
                                            );
                                          }
                                        })
                                      ) : (
                                        <>
                                          {" "}
                                          <LogoCard
                                            cardTitle={"No Topic"}
                                            // icon={"/Acc.png"}
                                            style={{
                                              ...CardStyle,
                                              fontSize: "15px",
                                              iconSize: 24,
                                              width: "17.1em",
                                              marginBottom: "15px",
                                              justifyContent: "flex-end",
                                              columnGap: "10px",
                                              borderRadius: "15px",
                                            }}
                                          />
                                        </>
                                      )}
                                    </CardContent>
                                  </Card>
                                </div>
                              </>
                            );
                          }
                        })}
                      </div>
                    </>
                  ) : (
                    <>
                      {/* Graphs view */}

                      <motion.div
                        initial={{ opacity: 0, y: -20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 1.0 }}
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          gap: 1,
                          rowGap: 5,
                          justifyContent: "space-around",
                        }}
                      >
                        {tableData?.map((item, ind) => {
                          //console.log(show);
                          return (
                            <Box key={ind}>
                              <Typography
                                lineHeight={0}
                                sx={{
                                  ...typographyStyles.subHeading,
                                  textAlign: "center",
                                }}
                                color="black"
                              >
                                {item[tableDataType.slice(6).toLowerCase()]}
                              </Typography>
                              <Box sx={{ width: "14em", height: "15em" }}>
                                <PieGraphNew
                                  data={{
                                    topic:
                                      item[
                                        tableDataType.slice(6).toLowerCase()
                                      ],

                                    correct: item.correctQuestions,
                                    incorrect: item.incorrectQuestions,
                                    skipped: item.skippedQuestions,
                                  }}
                                  color={GraphLegend.map((e) => e.shade)}
                                />
                              </Box>
                            </Box>
                          );
                        })}
                      </motion.div>
                    </>
                  )}
                  {view === "Table" ? (
                    <></>
                  ) : (
                    <Stack
                      direction="row"
                      spacing={2}
                      justifyContent={"center"}
                      mt={2}
                    >
                      {GraphLegend?.map((e, ind) => {
                        return (
                          <div
                            className="d-flex gap-1 align-items-center"
                            key={ind}
                          >
                            <div
                              style={{
                                borderRadius: "40%",
                                height: "24px",
                                width: "24px",
                                background: e.shade,
                              }}
                            ></div>
                            <span
                              style={{
                                fontSize: 13,
                                fontFamily: "var(--font-inter)",
                                fontWeight: 600,
                              }}
                            >
                              {e.Value}
                            </span>
                          </div>
                        );
                      })}
                    </Stack>
                  )}
                </Box>
              </Box>
            
            </>
          )}
        </Box>
      </Box>
    </>
  );
}

export default AnalysisAcross;
