import React, { useState, useEffect, useMemo, useRef } from "react";
import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import { Card, Skeleton, Typography } from "@mui/material";
import LeaderTable from "../Components/LeaderTable";
import { fetchLeaderBoard as getLeaderBoardData } from "../services/Analysis_api";
import MenuDrawer from "../Components/MenuDrawer";
import HeaderNew from "../Components/HeaderNew";
import { ToastContainer, toast } from "react-toastify";
import ErrorPage from "./ErrorPage";
import { useSelector } from "react-redux";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import WindowPeriodFallback from "../Common-comp/windowPeriodFallback";
import { LeaderBoardCard } from "../Common-comp/CustomizedAccordian";

function LeaderBoard() {
  const { attemptId, mockId } = useParams();
  const [loading, setLoading] = useState(true);
  const {isWindowPeriod} = useSelector(state => state.analysisData)
  const ref = useRef(null);
  const [isErr, setIsErr] = useState(false);
  const [errorMsg, setErrorMsg] = useState();
  const { name , uid} = useSelector((state) => state.userData);
  const [leaderListKeys, setLeaderListKeys] = useState();
  const [leaderList, setLeaderList] = useState();

  const [userData, setUserData] = useState();
  const [mockList, setMockList] = useState([]);
const { isMobile } = useSelector((state) => state.globalData);

  const ITEM_HEIGHT = "48";
  const ITEM_PADDING_TOP = 3;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      },
    },
  };


const SkeletonCards = () => {
  return (
    <>
      <Card
        sx={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          gap: 2,
          width: "100%",
          p: 2,
          boxShadow: 3,
          borderRadius: 5,
          border: "none",
        }}
      >
        <Skeleton animation="wave" variant="circular" width={50} height={50} />
        <Skeleton
          animation="wave"
          variant="rounded"
          // width="100%"
          height={60}
          style={{
            flexBasis: "auto",
            width: "100%",

            zIndex: 1,
          }}
        />
      </Card>
    </>
  );
};


  //sendAttemptId on change of mockList
  useEffect(() => {
    async function fetchLeaderBoard(attemptId) {
      try {
        // setLoading(true);
        const res = await getLeaderBoardData(mockId, attemptId, uid);
        console.log(res);
        if (res?.status == 200) {
          setLeaderList(res.data.data?.formattedList);
          setLeaderListKeys(res.data.data?.leaderListKeys);
          setUserData(res.data.data?.result);
          setMockList(res.data.data?.allMocks);
          setLoading(false);
        }
      } catch (err) {
        //console.log(err);
        setLoading(false);
        showToastMessage(err?.response?.data?.error);
      }
    }
    fetchLeaderBoard(attemptId);
  }, []);

  //console.log(filter, "fiilll");
  const showToastMessage = (obj) => {
    setIsErr(true);
    setErrorMsg(obj);
  };

  // making api call on mock change
  const handleChange = async (obj) => {
    try {
      // setLoading(true);
      const res = await getLeaderBoardData(obj.mockId, obj.attemptId, uid);
      console.log(res);
      if (res?.status == 200) {
        setLeaderList(res.data.data?.formattedList);
        setLeaderListKeys(res.data.data?.leaderListKeys);
        setUserData(res.data.data?.result);
        setLoading(false);
      }
    } catch (err) {
      //console.log(err);
      setLoading(false);
      showToastMessage(err?.response?.data?.error);
    }
  };

  
  console.log("🚀 ~ LeaderBoard ~ leaderList:", leaderList);
   console.log("🚀 ~ LeaderBoard ~ userData:", userData);
  

  return (
    <>
      <ToastContainer />
      <div style={{ width: "100vw" }}>
        {!isMobile && <MenuDrawer />}

        {isWindowPeriod ? (
          <WindowPeriodFallback />
        ) : isErr == true ? (
          <ErrorPage error={errorMsg} />
        ) : (
          <Box
            sx={{
              height: "auto",
              display: "flex",
              flexDirection: "column",
              p: 2,
              gap: "2em",
              position: "relative",
              ml: !isMobile && "65px",
            }}
            ref={ref}
          >
            <Box component="div" sx={{ height: "10%" }}>
              <HeaderNew />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: {
                  xs: "column",
                  sm: "column",
                  md: "row",
                  lg: "row",
                },
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: {
                    xs: "column",
                    sm: "column",
                    md: "row",
                    lg: "row",
                  },
                  gap: 1,
                }}
              >
                {" "}
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <Typography
                    sx={{
                      fontSize: "30px",
                      fontWeight: 800,
                      color: "black",
                      fontFamily: "Inter",
                    }}
                  >
                    Leader Board :
                  </Typography>
                </Box>
                <Typography
                  sx={{
                    fontSize: "24px",
                    fontWeight: 600,
                    color: "#0057C8",
                    fontFamily: "Inter",
                    marginTop:{md:"6px"},
                  }}
                >
                  {name}
                </Typography>
              </Box>
              <div>
                <FormControl
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    columnGap: 3,
                    mt:{sm:2,md:2,lg:2,xs:2}
                  }}
                >
                  <Select
                    defaultValue={"select"}
                    onChange={(e) => handleChange(e.target.value)}
                    input={
                      <OutlinedInput
                        sx={{
                          width: 127,
                          borderRadius: 2,
                          height: 32,
                          fontSize: "12px",
                          fontWeight: 700,
                          fontFamily: "var(--font-inter)",

                          ".MuiOutlinedInput-notchedOutline": {
                            border: 1,
                            borderColor: "#809EB9",
                          },
                          "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                            {
                              border: 1,
                              borderColor: "#809EB9",
                            },
                          "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                            {
                              border: 2,
                              borderColor: "#809EB9",
                            },
                        }}
                      />
                    }
                    MenuProps={MenuProps}
                    inputProps={{ "aria-label": "Select value" }}
                  >
                    <MenuItem value={"select"} disabled>
                      <em>Select</em>
                    </MenuItem>
                    {mockList?.map((item, ind) => (
                      <MenuItem
                        key={ind}
                        value={item}
                        sx={{
                          fontFamily: "var(--font-inter)",
                          fontSize: "11px",
                          fontWeight: "600",
                        }}
                      >
                        {item.title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>{" "}
            </Box>

            {/* Table start */}
            <div>
              {isMobile ? (
                loading ? (
                  [...Array(10)].map((_, ind) => (
                    <div className="mt-3">
                      <SkeletonCards key={ind} />
                    </div>
                  ))
                ) : (
                  userData && (
                    <>
                      {" "}
                      <LeaderBoardCard
                        isLoading={loading}
                        style={{
                          color: "black",
                          background: "#F5F9FF",
                          border: "black",
                        }}
                        Data={{
                          rank: userData.Rank,
                          name: userData.Name,
                          qaPercentile: userData.Quants,
                          lrdiPercentile: userData.LRDI,
                          varcPercentile: userData.VARC,
                          overallPercentile: userData?.["Overall Percentile"],
                          overallScore: userData?.["Overall Score"],
                        }}
                      />
                      {leaderList?.map((item, index) => (
                        <div className="mt-3">
                          <LeaderBoardCard
                            isLoading={loading}
                            style={{
                              color:
                                index == 0
                                  ? "#e0e0e0"
                                  : index == 1
                                  ? "#e0e0e0"
                                  : index == 2
                                  ? "#e0e0e0"
                                  : "#2e2e2e",
                              background:
                                index == 0
                                  ? "#FF7A00"
                                  : index == 1
                                  ? "#4B87FC"
                                  : index == 2
                                  ? "#B53FFD"
                                  : "white",
                              // background: "#F5F9FF",
                              border: "black",
                            }}
                            Data={{
                              rank: item.Rank,
                              name: item.Name,
                              qaPercentile: item.Quants,
                              lrdiPercentile: item.LRDI,
                              varcPercentile: item.VARC,
                              overallPercentile: item?.["Overall Percentile"],
                              overallScore: item?.["Overall Score"],
                            }}
                          />
                        </div>
                      ))}
                    </>
                  )
                )
              ) : (
                <LeaderTable
                  userData={userData}
                  leaderList={leaderList}
                  leaderListKeys={leaderListKeys}
                  isLoading={loading}
                />
              )}
            </div>
            {/* Table end */}
          </Box>
        )}
      </div>
    </>
  );
}


export default LeaderBoard;
