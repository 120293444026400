import React, { useEffect, useRef, useState } from "react";
import MenuDrawer from "../../Components/MenuDrawer";
import HeaderNew from "../../Components/HeaderNew";
import { Avatar, Box, Divider, IconButton, Typography, } from "@mui/material";
import { typographyStyles } from "../../styleSheets/StyleNew";
import MultipleSelect from "../../Common-comp/SelectField";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import { IoIosArrowForward } from "react-icons/io";
import Button from "@mui/material/Button";
import PieGraph from "../../Components/BarGraphErrorTracker";
import { fetchErrorTracker } from "../../services/Analysis_api";
import { useNavigate, useParams } from "react-router-dom";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import Latex from "react-latex-next";
import {
  IncorrectDetailing,
  CorrectDetailing,
  SkippedDetailing,
} from "../../services/DataFiles";
import { ToastContainer, toast } from "react-toastify";
import ErrorPage from ".././ErrorPage";
import { decode } from "base-64";
import { OutlinedInput } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import { useSelector } from "react-redux";
import WindowPeriodFallback from "../../Common-comp/windowPeriodFallback";
import { CardHeader } from "react-bootstrap";


const disableStyle = {
  ":disabled": {
    backgroundColor: "#E5E5E9",
    color: "black",
    fontWeight: 600,
    fontSize: 13,
    borderRadius: "5px",
    fontFamily: "var( --font-inter)",
    "& > span": {
      color: "green",
    },
  },
};
const ITEM_HEIGHT = "48";
const ITEM_PADDING_TOP = 3;
const MenuProps = {
  MenuListProps: {
    autoFocusItem: true,
  },
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

const filter1 = [
  { name: "Incorrect", value: "incorrect" },
  { name: "Correct", value: "correct" },
  { name: "Skipped", value: "skipped" },
];

const filter4 = [
  { name: "All Questions", value: "All Questions" },
  { name: "Easy", value: "easy" },
  { name: "Moderate", value: "medium" },
  { name: "Hard", value: "hard" },
];

function ErrorTracker() {
  const { attemptId, mockId } = useParams();
  const [graphData, setGraphData] = useState([]);
  const [colorDetail, setColorDetail] = useState(null);
  const [priority, setPriority] = useState(null);
  const [data, setData] = useState([]); //Main Data store
  const [correction, setCorrection] = useState(); // correct or Incorrect or skipped state
  const [section, setSection] = useState(0); // SectionINdex
  const [topic, setTopic] = useState("all"); // topic wise
  const [arr, setArr] = useState([]); //main Data
  const [show, setShow] = useState([]); // changeable state come from filter`
  const [isErr, setIsErr] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [colorDetailing, setColorDetailing] = useState(IncorrectDetailing);
  const [tracker, setTracker] = useState("all");
  const navigate = useNavigate();
  const ref = useRef(null);
  const [isLoading, setLoading] = useState(true);
  const { uid } = useSelector((state) => state.userData);
  const { isWindowPeriod } = useSelector((state) => state.analysisData);
  const { isMobile } = useSelector((state) => state.globalData);

  //  calling api
  useEffect(() => {
    const getData = async () => {
      try {
        const res = await fetchErrorTracker(attemptId, uid);
        console.log(res);
        setLoading(true);
        if (res?.status === 200) {
          setData(res.data.data?.sectionalData);
          setLoading(false);
        } else if (res?.status === 201) {
          showToastMessage(res?.data.error);
          setTimeout(
            () => navigate(`/viewsolutions/${mockId}/${attemptId}`),
            3500
          );
          setLoading(false);
        } else {
          //console.log("Error in fetching data: ", res);
          setLoading(false);
        }
      } catch (err) {
        //console.log(err);
        setLoading(false);
        showToastMessage(err?.response?.data?.error.message);
      }
    };
    getData();
  }, []);

  // Calculating the Graph data
  useEffect(() => {
    let graphData = {};
    if (correction === "correct") {
      setColorDetailing(CorrectDetailing);
      CorrectDetailing.slice(1).forEach((e) => {
        graphData[e.value] = 0;
      });
      show?.forEach((e) => {
        if (e.tracker !== null) {
          graphData[e.tracker] += 1;
        }
      });
    } else if (correction === "incorrect") {
      setColorDetailing(IncorrectDetailing);
      IncorrectDetailing.slice(1).forEach((e) => {
        graphData[e.value] = 0;
      });
      show?.forEach((e) => {
        if (e.tracker !== null) {
          graphData[e.tracker] += 1;
        }
      });
    } else if (correction === "skipped") {
      setColorDetailing(SkippedDetailing);
      SkippedDetailing.slice(1).forEach((e) => {
        graphData[e.value] = 0;
      });
      show?.forEach((e) => {
        if (e.tracker !== null) {
          graphData[e.tracker] += 1;
        }
      });
    }

    console.log(graphData);
    setGraphData(graphData);
  }, [correction, section, topic, priority, show]);

  // update the "show" state whenever the filters change

  function filterData() {
    let filteredData = data?.[section]?.questions;
    if (correction) {
      filteredData = filteredData?.filter((e) => e.response == correction);
    }
    console.log(filteredData);
    if (topic) {
      if (topic === "all") {
      } else {
        filteredData = filteredData.filter((e, ind) =>
          e.topic.some((i) => i.title == topic)
        );
      }
    }
    switch (priority) {
      case "All Questions":
        // filteredData = filteredData.filter((item) => item);
        break;
      case "easy":
        filteredData = filteredData.filter((item) => item.level === priority);
        break;

      case "medium":
        filteredData = filteredData.filter((item) => item.level === priority);
        break;

      case "hard":
        filteredData = filteredData.filter((item) => item.level === priority);
        break;

      default:
        break;
    }
    if (tracker === "all") {
    } else {
      filteredData = filteredData.filter((item) => item.tracker === tracker);
    }
    console.log(filteredData);
    setShow(filteredData);
  }

  useEffect(filterData, [correction, section, topic, priority, tracker]);
  const showToastMessage = (msg) => {
    setIsErr(true);
    setErrorMsg(msg);
  };
  console.log(data[section]?.topic_list);
  console.log(correction, "correction");
  console.log(section, "section Index");
  console.log(topic, "topic");
  console.log(priority, "priority");

  console.log(Object.values(graphData).map((e)=>e), "graphData");

  return (
    <>
      <ToastContainer />
      <Box component="main" sx={{ height: "100vh" }}>
        {!isMobile && <MenuDrawer />}
        {isWindowPeriod ? (
          <WindowPeriodFallback />
        ) : isErr == true ? (
          <ErrorPage error={errorMsg} />
        ) :(
          <Box
            sx={{
              p: 2,
            
              height: "100%",
            }}
            ref={ref}
          >
            {/* Header */}
            <Box component="header">
              <HeaderNew />
            </Box>

            {isLoading ? (
              <div
                className="d-flex align-items-center flex-column gap-2 justify-content-center"
                style={{ width: "100%", height: "80%" }}
              >
                <div class="loading-container">
                  <div class="loading"></div>
                  <div id="loading-text">Loading...</div>
                </div>
              </div>
            ) : (
              <>
                <Box
                  component="div"
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    mt: 4,
                  }}
                >
                  {" "}
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      flexDirection: "row",
                      flexWrap:"wrap",
                      justifyContent:"space-between",
                      gap: 1,
                    }}
                  >
                    <FormControl>
                      <Select
                        defaultValue={0}
                        // value={selected}
                        input={
                          <OutlinedInput
                            sx={{
                              width: "100%",
                              borderRadius: 2,
                              height: "70%",
                              fontSize: "12px",
                              fontWeight: 700,
                              fontFamily: "var(--font-inter)",

                              ".MuiOutlinedInput-notchedOutline": {
                                border: 1,
                                borderColor: "darkgrey",
                              },
                              "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                {
                                  border: 1,
                                  borderColor: "darkgrey",
                                },
                              "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  border: 2,
                                  borderColor: "darkgrey",
                                },
                            }}
                          />
                        }
                        MenuProps={MenuProps}
                        inputProps={{ "aria-label": "Select value" }}
                      >
                        <MenuItem value={""} disabled>
                          <em>Select</em>
                        </MenuItem>
                        {data?.map((item, ind) => (
                          <MenuItem
                            key={ind}
                            value={ind}
                            sx={{
                              fontFamily: "var(--font-inter)",
                              fontSize: "11px",
                              fontWeight: "600",
                            }}
                            onClick={(e) => {
                              setSection(ind);
                            }}
                          >
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl>
                      <Select
                        defaultValue={"all"}
                        // value={selected}
                        input={
                          <OutlinedInput
                            sx={{
                              width: "100%",
                              borderRadius: 2,
                              height: "70%",
                              fontSize: "12px",
                              fontWeight: 700,
                              fontFamily: "var(--font-inter)",

                              ".MuiOutlinedInput-notchedOutline": {
                                border: 1,
                                borderColor: "darkgrey",
                              },
                              "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                {
                                  border: 1,
                                  borderColor: "darkgrey",
                                },
                              "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  border: 2,
                                  borderColor: "darkgrey",
                                },
                            }}
                          />
                        }
                        MenuProps={MenuProps}
                        inputProps={{ "aria-label": "Select value" }}
                      >
                        <MenuItem value={""} disabled>
                          <em>Select</em>
                        </MenuItem>
                        <MenuItem
                          sx={{
                            fontFamily: "var(--font-inter)",
                            fontSize: "11px",
                            fontWeight: "600",
                          }}
                          value={"all"}
                          onClick={() => setTopic("all")}
                        >
                          All Topics
                        </MenuItem>
                        {data[section]?.topic_list?.map((item, ind) => (
                          <MenuItem
                            key={item._id}
                            value={item.title}
                            sx={{
                              fontFamily: "var(--font-inter)",
                              fontSize: "11px",
                              fontWeight: "600",
                            }}
                            onClick={(e) => setTopic(item.title)}
                          >
                            {item.title}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <div>
                      <MultipleSelect options={filter4} setType={setPriority} />
                    </div>
                    <div>
                      {" "}
                      <MultipleSelect
                        options={filter1}
                        setType={setCorrection}
                      />
                    </div>
                  </Box>
                </Box>
                <Typography
                  sx={{
                    ...typographyStyles.mainHeading,
                    pt: 2,
                  }}
                >
                  {" "}
                  Error Tracker
                </Typography>
            {/* -----------------------------------------------------|  MAIN COMP |--------------------------------------------------------------- */}
                <Box
                  component="main"
                  sx={{ display: "flex", width: "100%", height: "auto", flexDirection:"column" }}
                >
                  <Backdrop
                    sx={{
                      zIndex: (theme) => theme.zIndex.drawer - 1,
                      color: "#fff",
                    }}
                  />
                  {/* --------------------------------------------------Graph side div start ----------------------------------------------------- */}
                  <Box
                    sx={{
                      backgroundColor: "",
                      flexBasis: "40%",
                      borderRight: "2px solid #928F8F ",
                      justifyContent: " ",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        width: "auto",
                      }}
                    >
                      {Object?.values(graphData)?.some((e)=>e >0) ? (
                        <PieGraph
                          Data={graphData}
                          width={"97%"}
                          legend={false}
                        />
                      ) : (
                        <Card
                          sx={{
                            height: "20em",
                            // mr: 2,
                            color: "grey",
                            display: "flex",
                            alignItems: "center",
                            width: "100%",
                            justifyContent: "center",
                            textAlign: "center",
                            mt: 2,
                          }}
                        >
                          <CardContent>
                            Please fill the data in analysis tab <br></br>to
                            generate the over the report here...
                          </CardContent>
                        </Card>
                      )}
                    </Box>
                    <Box sx={{ mt: 2 }}>
                      <Typography
                        sx={{
                          ml: 2,
                          mb: 1,
                          fontWeight: "700",
                          fontSize: "18px",
                        }}
                      >
                        {`${
                          correction &&
                          correction.charAt(0).toUpperCase() +
                            correction.slice(1)
                            ? correction.charAt(0).toUpperCase() +
                              correction.slice(1)
                            : ""
                        } Questions (Filtered): ${show?.length}`}
                      </Typography>

                      {<GraphComp colorDetailing={colorDetailing} />}
                    </Box>
                  </Box>
                  {/* Graph side div end */}

                  {/*----------------------------------------------------Question side box start----------------------------------------------------*/}
                  <Box
                    sx={{
                      flexBasis: "60%",
                      px: 1,
                      overflow: "scroll",
                      height: "100%",
                    }}
                  >
                    {/* Color selection div */}
                    <div className="d-flex justify-content-start align-items-start flex-column">
                      <Typography
                        sx={{
                          ...typographyStyles.subHeading,
                          alignSelf: "flex-start",
                          fontWeight: "600",
                        }}
                      >
                        Question Summary
                      </Typography>

                      <div>
                        <Typography
                          sx={{
                            lineHeight: 3,
                            fontSize: 11,
                            fontWeight: 400,
                            fontFamily: "var(--font-inter)",
                          }}
                        >
                          Question Selector
                        </Typography>
                        <div className="d-flex gap-3">
                          {colorDetailing &&
                            colorDetailing.map((item, ind) => {
                              return (
                                <Tooltip
                                  title={item.value}
                                  placement="top"
                                  arrow
                                >
                                  <div
                                    key={ind}
                                    onClick={() => {
                                      setTracker(item.value);
                                    }}
                                    style={{
                                      background: item.color,
                                      width:
                                        colorDetail === item.value ? 29 : 26,
                                      height:
                                        colorDetail === item.value ? 29 : 26,
                                      borderRadius: "50%",
                                      cursor: "pointer",
                                      transition: "all 0.2s ease-in-out",
                                      boxShadow:
                                        colorDetail === item.value
                                          ? "0 0 10px rgba(0, 0, 0, 0.5)"
                                          : "none",
                                      border:
                                        colorDetail === item.value
                                          ? "0px solid #333"
                                          : "none",
                                    }}
                                  />
                                </Tooltip>
                              );
                            })}
                        </div>
                      </div>
                    </div>
                    {show?.length > 0 ? (
                      show.map((item, index) => {
                        const colorObj = colorDetailing.find(
                          (detail) => item.tracker === detail.value
                        );

                        const borderColor = colorObj
                          ? colorObj.color
                          : "transparent";

                        return (
                          <Card
                            sx={{
                              background: "#eaeaea",
                              boxShadow: "none",
                              color: "black",
                              borderLeft: "8px solid",
                              borderRadius: "5px 10px 10px 5px",
                              borderColor: borderColor,
                              width: "100%",
                              mt: 2,
                            }}
                          >
                            <CardContent sx={{ textAlign: "left" }}>
                              <span style={{ float: "left", marginRight: 2 }}>
                                {"Q" + (index + 1) + ". "}
                              </span>
                              <Latex>{item?.questionId?.question || ""}</Latex>
                            </CardContent>

                            <CardActions
                              sx={{
                             
                                px: 3,
                                display: "flex",
                                justifyContent:"flex-start",
                                flexDirection: "column",
                                gap: 2,
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  columnGap: 2,
                                  flexWrap: "wrap",
                                  rowGap: 2,

                                  width:"95%"
                              
                                }}
                              >
                                <Button
                                  size="small"
                                  disabled={true}
                                  variant="contained"
                                  sx={{
                                    ...disableStyle,
                                    ":disabled": {
                                      color: "#000000",
                                    },
                                    "& > span": {
                                      color:
                                        item.difficulty === "Easy"
                                          ? "#00C838 !important"
                                          : item.difficulty === "Moderate"
                                          ? "#FF6238"
                                          : "#FF0000",
                                    },
                                  }}
                                >
                                  Difficulty:
                                  <span>
                                    {item?.questionId?.difficulty_level_manual}
                                  </span>
                                </Button>
                                <Button
                                  size="small"
                                  disabled={true}
                                  variant="contained"
                                  sx={{
                                    ...disableStyle,
                                    ":disabled": {
                                      color: "#636363",
                                    },
                                    "& > span": {
                                      color: "#000000 !important",
                                    },
                                  }}
                                >
                                  Time :<span>{item.duration}</span>
                                </Button>
                              </Box>

                         
                                <Button
                                  size="small"
                                  endIcon={<IoIosArrowForward />}
                                  sx={{ background: "#3A36DB",width:"95%" }}
                                  variant="contained"
                                  onClick={() =>
                                    navigate(
                                      `/viewsolutions/${mockId}/${attemptId}`,
                                      {
                                        state: {
                                          questionId: item.questionId?._id,
                                        },
                                      }
                                    )
                                  }
                                >
                                  Solution
                                </Button>
                             
                            </CardActions>
                          </Card>
                        );
                      })
                    ) : (
                      <Card
                        sx={{
                          height: "20em",
                          mr: 2,
                          color: "darkgrey",
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                          justifyContent: "center",
                          textAlign: "center",
                          mt: 3,
                        }}
                      >
                        <CardContent>No Questions Found!!!</CardContent>
                      </Card>
                    )}
                  </Box>
                  {/*Question side box end*/}
                </Box>
              </>
            )}
          </Box>
        )}
      </Box>
    </>
  );
}


const GraphComp = ({ colorDetailing }) => {
  return (
    <Box sx={{ width: "100%" }}>
      {colorDetailing &&
        colorDetailing.slice(1).map((item, _) => {
          return (
            <Box
              component="item"
              sx={{
                display: "flex",
                p: 1,
                textAlign: "left",
              }}
            >
              <Box
                sx={{
                  bgcolor: item.color,
                  borderRadius: "50%",
                  marginRight: "5px",
                  minWidth: "21px",
                  maxHeight: "21px",
                }}
              ></Box>
              <Typography variant="paragraph">{item.value}</Typography>
            </Box>
          );
        })}
    </Box>
  );
};

export default ErrorTracker;
